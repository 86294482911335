import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { veiculoAction, marcaAction } from '../../_actions'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment'
import TitleContentInformation from '../TitleContentInformation'
import HelpIcon from '@mui/icons-material/Help';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Autocomplete,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  Switch,
  IconButton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  Tooltip
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { placaMask, MANUAIS_CATEGORIAS } from '../../utils'
import { withRouter } from 'react-router-dom'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Page } from 'components'
import {
  ValidatorForm
} from 'react-material-ui-form-validator'
import CustomButton from '../../components/CustomButton/CustomButton'
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const RelatorioGestaoAbastecimento = (props) => {

  const dispatch = useDispatch()
  const isFirstRender = useRef(true);
  const { classes, className, ...rest } = props
  const { totalRegistros } = props.veiculo || 0
  const { isLoading } = props.veiculo

  const [valueMarca, setValueMarca] = useState(null);
  const [inputValueMarca, setInputValueMarca] = useState('');

  const [valueVeiculo, setValueVeiculo] = useState(null);
  const [inputValueVeiculo, setInputValueVeiculo] = useState('');

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const { veiculo } = useSelector(
    (state) => state.veiculo)

  const { marca } = useSelector(
    (state) => state.marca)

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [ready, setReady] = React.useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  useEffect(() => {
    if (idOrgao && idOrgao != 0 && idOrgao != '') {
      dispatch(veiculoAction.getVeiculoSelect(idOrgao))
    } else {
      dispatch(veiculoAction.clear());
    }
  }, [idOrgao])

  let filterModel = {
    PlacaVeiculo: valueVeiculo && valueVeiculo.placa ? valueVeiculo.placa.split('-')[0].trim() : '',
    MarcaVeiculo: valueMarca && valueMarca.nome ? valueMarca.nome : '',
    IdOrgao: idOrgao,
    Page: page,
    Limit: limit
  };

  useEffect(() => {
    dispatch(marcaAction.getMarcaSelect())
    dispatch(veiculoAction.clear());
  }, []);

  const optionsVeiculos = veiculo?.map(u => ({ id: u.value, placa: u.text }));

  const optionsMarcas = marca?.map(u => ({ id: u.value, nome: u.text }));

  const handleChangeAutoComplete = (obj, array, nameArray) => {
    if (obj != null) {
      let selected = array.filter(
        (item) => item.value == obj.id
      )

      if (nameArray == 'veiculo') {
        dispatch(veiculoAction.changeDetailsVeiculo(selected[0]))
      }

      if (nameArray == 'marca') {
        dispatch(marcaAction.changeDetailsMarca(selected[0]))
      }
    } else {

    }
  }

  useEffect(() => {
    if (valueVeiculo) {
      handleChangeAutoComplete(valueVeiculo, veiculo, 'veiculo')
    }
  }, [valueVeiculo])

  useEffect(() => {
    if (valueMarca) {
      handleChangeAutoComplete(valueMarca, marca, 'marca')
    }
  }, [valueMarca])

  const handleSubmit = (event) => {
    dispatch(veiculoAction.getVeiculosGestaoAbastecimento(filterModel, true));
  }

  useEffect(() => {
    // se é a primeira renderização, não faz a chamada
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // dispatch(veiculoAction.getVeiculosGestaoAbastecimento(filterModel, true));
  }, [page, limit]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.veiculo.isLoading])

  return (
    <Page className={classes.root} title="Relatório - Gestão de Abastecimento">
      <AccessControl
        rule={'veiculos.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorio"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                      <TitleContentInformation
                        subTitle={'RELATÓRIO'}
                        title={'Relatório de Gestão de Abastecimento'}
                      />
                      <Grid item lg={1} md={1} xl={1} xs={12}>
                        <IconButton
                          aria-label="Ajuda"
                          component="a"
                          onClick={() =>
                            handleOpenModalHelp()
                          }
                          size="small"
                        >
                          <Tooltip title={'Ajuda'}>
                            <Tooltip>
                              <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                            </Tooltip>
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={5} md={5} xl={5} xs={12} style={{ marginTop: 17 }}>
                        <Autocomplete
                          value={valueVeiculo}
                          onChange={(event, newValue) => {
                            setValueVeiculo(newValue);
                          }}
                          inputValue={inputValueVeiculo}
                          onInputChange={(event, newInputValueVeiculo) => {
                            setInputValueVeiculo(newInputValueVeiculo);
                          }}
                          id="controllable-states-demo"
                          options={optionsVeiculos}
                          margin="normal"
                          variant="outlined"
                          defaultValue={valueVeiculo}
                          getOptionLabel={(optionVeiculo) => (optionVeiculo ? optionVeiculo.placa : '')}
                          renderInput={(params) => <TextField {...params} label="Veículo... " fullWidth />}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12} style={{ marginTop: 17 }}>
                        <Autocomplete
                          value={valueMarca}
                          onChange={(event, newValue) => {
                            setValueMarca(newValue);
                          }}
                          inputValue={inputValueMarca}
                          onInputChange={(event, newInputValueMarca) => {
                            setInputValueMarca(newInputValueMarca);
                          }}
                          id="controllable-states-demo"
                          options={optionsMarcas}
                          margin="normal"
                          variant="outlined"
                          defaultValue={valueMarca}
                          getOptionLabel={(optionMarca) => (optionMarca ? optionMarca.nome : '')}
                          renderInput={(params) => <TextField {...params} label="Marca do Veículo " fullWidth />}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                      <Grid item xl={12} lg={12} md={12} xs={12} style={{
                        alignItems: 'bottom',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right'
                      }}>
                        <Grid>
                          <CustomButton
                            type="submit"
                            variant="contained"
                            dark={true}
                            disabled={props.veiculo.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.veiculo.isLoading && <CircularProgress size={24} />}
                          >
                            {props.veiculo.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>VEÍCULO</strong></TableCell>
                        <TableCell><strong>MARCA</strong></TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell rowSpan={6} colSpan={3} align="center">Nenhum registro encontrado</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    count={totalRegistros}
                    page={page}
                    onPageChange={handleChangePage}
                    labelRowsPerPage="Linhas por página"
                    labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeLimit}
                  />
                </TableContainer>
              </Card>
            </Grid>
            {
              (modalHelp ?
                <HelpModal
                  toggle={toggleModalHelp}
                  modal={modalHelp}
                  props={props}
                  tela={MANUAIS_CATEGORIAS.RELATORIO_GESTAO_ABASTECIMENTO}
                />
                :
                null
              )
            }
          </Grid>
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
RelatorioGestaoAbastecimento.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    veiculo: state.veiculo
  }
}
const connectedRelatorioGestaoAbastecimentoPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(RelatorioGestaoAbastecimento))
)
export { connectedRelatorioGestaoAbastecimentoPage as RelatorioGestaoAbastecimento }
