const initialState = {
  totalRegistros: 0,
  isLoading: false,
  orgao: [],
  id: '',
  nome: '',
  idCidade: 0,
  idEstado: '',
  excluido: '',
  celular: '',
  cnpj: '',
  email: '',
  endereco: '',
  telefone: '',
  cidade: [],
  cadastroLiberado: false,
  telemetria: false,
  gestaoAbastecimento: false,
  seguroVeicular: false,
  locacaoVeicular: false,
  excluirPrestadorAutomatico: false,
  ordensServicoPublico: true,
  usuariosOrgaos: []
}

export function orgao(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_ORGAO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_ORGAO_SUCCESS':
      return {
        ...state,
        id: action.orgao.id,
        isLoading: false,
        nome: action.orgao.nome,
        celular: action.orgao.celular,
        cnpj: action.orgao.cnpj,
        email: action.orgao.email,
        endereco: action.orgao.endereco,
        telefone: action.orgao.telefone,
        idCidade: action.orgao.idCidade,
        idEstado: action.orgao.idEstado,
        cadastroLiberado: action.orgao.cadastroLiberado,
        telemetria: action.orgao.telemetria,
        gestaoAbastecimento: action.orgao.gestaoAbastecimento,
        seguroVeicular: action.orgao.seguroVeicular,
        locacaoVeicular: action.orgao.locacaoVeicular,
        excluirPrestadorAutomatico: action.orgao.excluirPrestadorAutomatico,
        cidade: action.orgao.cidade,
        usuariosOrgaos: action.usuariosOrgaos,
        ordensServicoPublico: action.ordensServicoPublico
      }
    case 'CREATE_ORGAO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_ORGAO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_ORGAO_SUCCESS':
      return {
        ...state,
        id: action.orgao.id,
        isLoading: false,
        nome: action.orgao.nome,
        celular: action.orgao.celular,
        cnpj: action.orgao.cnpj,
        email: action.orgao.email,
        endereco: action.orgao.endereco,
        telefone: action.orgao.telefone,
        idCidade: action.orgao.idCidade,
        idEstado: action.orgao.idEstado,
        cidade: action.orgao.cidade,
        cadastroLiberado: action.orgao.cadastroLiberado,
        telemetria: action.orgao.telemetria,
        gestaoAbastecimento: action.orgao.gestaoAbastecimento,
        seguroVeicular: action.orgao.seguroVeicular,
        locacaoVeicular: action.orgao.locacaoVeicular,
        excluirPrestadorAutomatico: action.orgao.excluirPrestadorAutomatico,
        usuariosOrgaos: action.orgao.usuariosOrgaos,
        ordensServicoPublico: action.orgao.ordensServicoPublico
      }
    case 'EDIT_ORGAO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_ORGAO':
      return {
        ...state,
        orgao: action.orgao,
        totalRegistros: action.totalRegistros
      }
    case 'ORGAO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        nome: action.nome,
        celular: action.celular,
        cnpj: action.cnpj,
        email: action.email,
        endereco: action.endereco,
        telefone: action.telefone,
        idCidade: action.idCidade,
        idEstado: action.idEstado,
        cadastroLiberado: action.cadastroLiberado,
        telemetria: action.telemetria,
        gestaoAbastecimento: action.gestaoAbastecimento,
        seguroVeicular: action.seguroVeicular,
        locacaoVeicular: action.locacaoVeicular,
        excluirPrestadorAutomatico: action.excluirPrestadorAutomatico,
        cidade: action.cidade,
        usuariosOrgaos: action.usuariosOrgaos,
        ordensServicoPublico: action.ordensServicoPublico
      }
    case 'ORGAO_CLEAR':
      return initialState
    case 'ORGAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'ORGAO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'ORGAO_CLEAR_USUARIOS_ORGAOS':
      return {
        ...state,
        usuariosOrgaos: []
      }
    case 'ORGAO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
