import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { veiculoAction } from '../../_actions'
import PropTypes from 'prop-types'
import TitleContent from '../TitleContent'
import {
  Grid,
  TextField,
  Paper,
  Skeleton,
  TablePagination,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { withRouter, useLocation } from 'react-router-dom'
import { MANUAIS_CATEGORIAS } from '../../utils'
import { AccessControl } from '../../components/Utils/AccessControl'
import { Page } from 'components'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: '13px !important'
  }
})

const ListVeiculosTelemetria = (props) => {

  const dispatch = useDispatch()
  const location = useLocation();
  const { classes } = props
  const { veiculo } = props.veiculo
  const { totalRegistros } = props.veiculo
  const { isLoading } = props.veiculo
  const [order] = useState('data');
  const [direction] = useState('desc');

  const searchParams = new URLSearchParams(location.search);

  //parâmetro que passa a página e o limite para a chamada
  const limite = searchParams.get('Limit');
  const pagina = searchParams.get('Page');
  const dataInicioParam = searchParams.get('DataInicio');

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [ready, setReady] = React.useState(false);

  const getTodayDate = (): string => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState(getTodayDate());

  useEffect(() => {

    const fetchData = (page, limit) => {

      let filterModel = {
        Page: pagina ? pagina : page,
        Limit: limite ? limite : limit,
        Order: order,
        Direction: direction,
        DataInicio: dataInicioParam && dataInicioParam != '' ? dataInicioParam : dataInicio,
        DataFim: dataFim
      };

      if (dataInicio && dataInicio != '' && dataFim && dataFim != '') {
        dispatch(veiculoAction.getResumoFrotaVeiculo(filterModel, true));
      } else {
        dispatch(veiculoAction.clear())
      }
    }

    fetchData(page, limit, order, direction);

  }, [page, limit, order, direction, dataInicio, dataFim, pagina, limite]);

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.veiculo.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Page className={classes.root} title="Listas de Veículos Cadastrados na Telemetria">
      <AccessControl
        rule={'veiculos.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            <TitleContent
              length={veiculo.length}
              subTitle={'GERÊNCIA DE TELEMETRIA'}
              title={'Lista de Veículos Cadastrados na Telemetria'}
              telaManual={MANUAIS_CATEGORIAS.LISTAGEM_VEICULO_TELEMETRIA}
            />
            <br />
            <Grid container spacing={3} style={{ justifyContent: 'end' }}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <TextField
                  className={classes.textField}
                  fullWidth
                  id="data"
                  InputLabelProps={{
                    shrink: true
                  }}
                  type="date"
                  label="Data Início"
                  margin="normal"
                  onChange={(e) => setDataInicio(e.target.value)}
                  value={dataInicio || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <TextField
                  className={classes.textField}
                  fullWidth
                  id="data"
                  InputLabelProps={{
                    shrink: true
                  }}
                  type="date"
                  label="Data Fim *"
                  margin="normal"
                  onChange={(e) => setDataFim(e.target.value)}
                  value={dataFim || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Distância</TableCell>
                    <TableCell>Velocidade Média</TableCell>
                    <TableCell>Velocidade Máxima</TableCell>
                    <TableCell>Quantidade Veículos</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== veiculo && veiculo.length
                    ? veiculo.map((n) => {
                      return (
                        <TableRow hover key={n.id} className={classes.textField}>
                          <TableCell component="th" scope="row">
                            {n.distancia ? n.distancia : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.veloc_Media ? n.veloc_Media : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.veloc_Maxima ? n.veloc_Maxima : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.quantidade_Veiculos ? n.quantidade_Veiculos : 'NÃO'}
                          </TableCell>
                        </TableRow >
                      )
                    })
                    :
                    (
                      isLoading ?
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableRow>
                          <TableCell rowSpan={4} colSpan={4} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div >
        )
        }
      />
    </Page >
  )
}
ListVeiculosTelemetria.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedListVeiculosTelemetriaPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(ListVeiculosTelemetria))
)
export { connectedListVeiculosTelemetriaPage as ListVeiculosTelemetria }
