import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import CustomButton from '../../components/CustomButton/CustomButton'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { useDebounce } from 'use-debounce';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Input,
  IconButton,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  Tooltip
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import {
  authActions,
  orgaoAction,
  cidadeAction,
  estadoAction,
  usuarioAction,
  veiculoAction
} from '../../_actions'
import HelpIcon from '@mui/icons-material/Help';
import { withRouter } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import { TIPO_USUARIO, history, cnpjMask, stringUtils, phoneMask, placaMask, MANUAIS_CATEGORIAS } from '../../utils'
import { Page } from 'components'
import SaveIcon from '@mui/icons-material/Save';
import { UsuarioEditModal } from './usuarioEditModal.component';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { VeiculoOrgaoEditModal } from '../Veiculos/veiculoOrgaoEditModal.component';
import { HelpModal, EditorHeader } from '../../components';

const SwitchBlack = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#000000',
    }
  }
}));

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const CustomCardActions = styled(CardActions)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    textAlign: 'center'
  },
}));

const CustomGridButtonsAction = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'right',
  flexDirection: 'row',
  textAlign: 'right',
  justifyContent: 'right',
  [theme.breakpoints.down('sm')]: {
    paddingTop: 3,
    display: 'block',
    textAlign: 'center'
  },
}));

const OrgaoEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, orgao, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const confirm = useConfirm()

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('placa');
  const [direction] = useState('asc');

  const [ready, setReady] = React.useState(false);

  const [, forceUpdate] = React.useState(0);

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  const [valueEstado, setValueEstado] = React.useState(null);
  const [inputValueEstado, setInputValueEstado] = React.useState('');

  const [modalUsuario, setModalUsuario] = useState(false);
  const toggleUsuario = () => setModalUsuario(!modalUsuario);

  const [modalVeiculo, setModalVeiculo] = useState(false);
  const toggleVeiculo = () => setModalVeiculo(!modalVeiculo);
  const [idVeiculo, setIdVeiculo] = useState(0);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const { cidade } = useSelector(
    (state) => state.cidade
  )

  const { estado } = useSelector(
    (state) => state.estado
  )

  const options = cidade.map(u => ({ id: u.value, nome: u.text }));

  const optionsEstado = estado.map(u => ({ id: u.value, nome: u.text }));

  useEffect(() => {

    dispatch(orgaoAction.clear())
    dispatch(estadoAction.getEstadoSelect())

    if (params.id) {
      dispatch(orgaoAction.getOrgaoById(params.id))
    } else {
      dispatch(orgaoAction.clear())
    }

  }, []);

  useEffect(() => {
    if (props.orgao.idCidade != 0 && params.id != undefined) {
      setValue(props.orgao.cidade)
    }
  }, [props.orgao.idCidade])

  const handleAprovaCadastroOrgao = () => {
    confirm({
      title: 'Você deseja liberar o acesso ao sistema para este Órgão?',
      description: 'Essa operação é irreversível',
      disabled: props.orgao.isLoading,
      confirmationText: props.orgao.isLoading ? 'Aprovando...' : 'Sim, aprovar!',
    }).then(() => {
      dispatch(orgaoAction.editAprovaCadastroOrgao(params.id))
    })
  }

  const handleRecusaCadastroOrgao = () => {
    confirm({
      title: 'Você deseja recusar o acesso ao sistema para este Órgão?',
      description: 'Essa operação é irreversível',
      disabled: props.orgao.isLoading,
      confirmationText: props.orgao.isLoading ? 'Recusando...' : 'Sim, recusar!',
    }).then(() => {
      dispatch(orgaoAction.editRecusaCadastroOrgao(params.id))
    })
  }

  const handleRemoveOnList = id => {
    confirm({
      title: 'Você deseja excluir este usuário?',
      description: 'Essa operação desvinculará o usuário deste órgao',
      disabled: props.orgao.isLoading,
      confirmationText: props.orgao.isLoading ? 'Excluindo usuário...' : 'Sim, excluir!',
    }).then(() => {
      props.orgao.usuariosOrgaos = props.orgao.usuariosOrgaos.filter(ts => ts.idUsuario != parseInt(id));
      forceUpdate(n => !n);
    })
  };

  const handleOpenModal = () => {
    toggleUsuario();
    dispatch(usuarioAction.clear())
  }

  const handleOpenModalVeiculo = (id) => {
    toggleVeiculo();
    if (id && id != 0 && id != undefined) {
      setIdVeiculo(id)
    } else {
      setIdVeiculo(0)
    }
  }

  const { veiculo, totalRegistros, isLoading } = useSelector(
    (state) => state.veiculo
  )

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term.split('-').join('').replace('-', ''),
        Order: order,
        Direction: direction,
        IdOrgao: params.id
      };

      dispatch(veiculoAction.getVeiculo(filterModel, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, params.id, modalVeiculo]);

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [ready, isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este Veículo?',
      description: 'Essa operação é irreversível',
      disabled: isLoading,
      confirmationText: isLoading ? 'Excluindo veículo...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(veiculoAction.deleteVeiculoOrgaoById(id, params.id))
    })
  }

  const handleChangeTerm = prop => event => {
    setTerm(event.target.value);
  };

  useEffect(() => {
    if (valueEstado && valueEstado.id) {
      dispatch(cidadeAction.getCidadeByIdEstado(valueEstado.id))
    }
  }, [valueEstado?.id])

  useEffect(() => {
    if (params.id != undefined && props.orgao.idEstado != 0) {
      setValueEstado({
        'id': estado?.find(m => m.value == props.orgao.idEstado)?.value,
        'nome': estado?.find(m => m.value == props.orgao.idEstado)?.text
      })
    }
  }, [props.orgao.idEstado])

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value, cidade, 'cidade')
    }
  }, [value])

  useEffect(() => {
    if (valueEstado) {
      handleChangeAutoComplete(valueEstado, estado, 'estado')
    }
  }, [valueEstado])

  const handleChangeAutoComplete = (obj, array, nameArray) => {
    if (obj != null) {
      let selected = array.filter(
        (item) => item.value == obj.id
      )

      if (nameArray == 'estado') {
        dispatch(estadoAction.changeDetailsEstado(selected[0]))
      } else if (nameArray == 'cidade') {
        dispatch(cidadeAction.changeDetailsCidade(selected[0]))
      }

    } else {

    }
  }

  useEffect(() => {

  }, [ready, props.orgao.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(orgaoAction.onChangeProps(prop, event))
  }

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.orgao.usuariosOrgaos])

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      nome: props.orgao.nome,
      idCidade: value ? value.id : 0,
      idEstado: valueEstado ? valueEstado.id : props.orgao.idEstado,
      celular: props.orgao.celular && props.orgao.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
      cnpj: props.orgao.cnpj && props.orgao.cnpj.split('.').join('').replace(/[-/.]/g, ''),
      email: props.orgao.email ? props.orgao.email.toLowerCase() : '',
      endereco: props.orgao.endereco,
      ordensServicoPublico: props.orgao.ordensServicoPublico,
      telefone: props.orgao.telefone && props.orgao.telefone.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
      usuariosOrgaos: props.orgao.usuariosOrgaos,
      telemetria: props.orgao.telemetria,
      seguroVeicular: props.orgao.seguroVeicular,
      excluirPrestadorAutomatico: props.orgao.excluirPrestadorAutomatico,
      locacaoVeicular: props.orgao.locacaoVeicular,
      gestaoAbastecimento: props.orgao.gestaoAbastecimento
    }

    if (params.id) {
      dispatch(orgaoAction.editOrgaoInfo(params.id, payload, true))
    } else {
      dispatch(orgaoAction.createOrgao(payload))
    }
  };

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Órgão'}</b>
      </Typography>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Órgãos - Editar Órgão'
          : 'Gerência de Órgãos - Novo Órgão'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formOrgao"
                onSubmit={(event) => handleSubmit(event)}
              >
                <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <CardHeader
                    subheader="Cadastro de Órgãos"
                    title={(
                      params.id ?
                        <EditorHeader title='Editar Órgão' tabela='Orgao' chave={params.id} props={props} />
                        :
                        <InsertText />
                    )}
                  />
                  <Grid item lg={1} md={1} xl={1} xs={12}>
                    <IconButton
                      aria-label="Ajuda"
                      component="a"
                      onClick={() =>
                        handleOpenModalHelp()
                      }
                      size="small"
                    >
                      <Tooltip title={'Ajuda'}>
                        <Tooltip>
                          <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                        </Tooltip>
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />

                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('nome')}
                        value={props.orgao.nome ? props.orgao.nome.toUpperCase() : ''}
                        variant="outlined"
                        validators={['required', 'maxStringLength: 45']}
                        errorMessages={['Campo obrigatório!', 'Tamanho máximo do campo só pode ser de até 45 caracteres']}
                      />
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12} style={{ marginTop: 16 }}>
                      <Autocomplete
                        value={valueEstado}
                        onChange={(event, newValueEstado) => {
                          setValueEstado(newValueEstado);
                        }}
                        inputValue={inputValueEstado}
                        onInputChange={(event, newInputValueEstado) => {
                          setInputValueEstado(newInputValueEstado);
                        }}
                        id="controllable-states-demo"
                        options={optionsEstado}
                        margin="normal"
                        variant="outlined"
                        defaultValue={valueEstado}
                        isOptionEqualToValue={(option, value) => option.id == value.id}
                        getOptionLabel={option => option.nome}
                        renderInput={(params) => <TextField required {...params} label="Selecione um estado" fullWidth />}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ paddingTop: 31 }}>
                      <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={options}
                        margin="normal"
                        variant="outlined"
                        defaultValue={value}
                        isOptionEqualToValue={(option, value) => option.id == value.id}
                        getOptionLabel={option => option.nome}
                        renderInput={(params) => <TextField required {...params} label="Selecione uma cidade..." fullWidth />}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="cnpj"
                        label="CNPJ *"
                        margin="normal"
                        onChange={handleChange('cnpj')}
                        value={cnpjMask(props.orgao.cnpj) || ''}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={8} md={8} xl={8} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="endereco"
                        label="Endereço *"
                        margin="normal"
                        validators={['required', 'maxStringLength: 100']}
                        errorMessages={['Campo obrigatório!', 'Tamanho máximo do campo só pode ser de até 100 caracteres']}
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('endereco')}
                        value={props.orgao.endereco ? props.orgao.endereco.toUpperCase() : ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xl={4} xs={12}>
                      <FormControlLabel
                        control={
                          <SwitchBlack
                            color="default"
                            checked={props.orgao.ordensServicoPublico}
                            onChange={handleChange('ordensServicoPublico')}
                          />
                        }
                        label="Permite a visualização pública das Ordens de Serviço?"
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xl={4} xs={12}>
                      {/*se não for gestor, mostra o campo desabilitado*/}
                      <FormControlLabel
                        control={
                          <SwitchBlack
                            color="default"
                            disabled={!authActions.isGestor()}
                            checked={props.orgao.telemetria}
                            onChange={handleChange('telemetria')}
                          />
                        }
                        label="Possui telemetria"
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xl={4} xs={12}>
                      {/*se não for gestor, mostra o campo desabilitado*/}
                      <FormControlLabel
                        control={
                          <SwitchBlack
                            color="default"
                            disabled={!authActions.isGestor()}
                            checked={props.orgao.gestaoAbastecimento}
                            onChange={handleChange('gestaoAbastecimento')}
                          />
                        }
                        label="Possui Gestão de Abastecimento"
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xl={4} xs={12}>
                      {/*se não for gestor, mostra o campo desabilitado*/}
                      <FormControlLabel
                        control={
                          <SwitchBlack
                            color="default"
                            disabled={!authActions.isGestor()}
                            checked={props.orgao.seguroVeicular}
                            onChange={handleChange('seguroVeicular')}
                          />
                        }
                        label="Possui Seguro Veicular"
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xl={4} xs={12}>
                      {/*se não for gestor, mostra o campo desabilitado*/}
                      <FormControlLabel
                        control={
                          <SwitchBlack
                            color="default"
                            disabled={!authActions.isGestor()}
                            checked={props.orgao.locacaoVeicular}
                            onChange={handleChange('locacaoVeicular')}
                          />
                        }
                        label="Possui Locação Veicular"
                      />
                    </Grid>
                    <Grid item lg={4} md={6} xl={4} xs={12}>
                      {/*se não for gestor, mostra o campo desabilitado*/}
                      <FormControlLabel
                        control={
                          <SwitchBlack
                            color="default"
                            disabled={!authActions.isGestor()}
                            checked={props.orgao.excluirPrestadorAutomatico}
                            onChange={handleChange('excluirPrestadorAutomatico')}
                          />
                        }
                        label="Excluir Prestador após 3 avaliações negativas (2 estrelas ou menos)"
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                    <Divider />
                  </Grid>
                  <Grid container lg={12} md={12} xl={12} xs={12}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography component="h4" variant="subtitle" align="center">
                        Dados de contato
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          variant="outlined"
                          margin="normal"
                          validators={['required', 'isEmail']}
                          errorMessages={[
                            'Você precisa informar o e-mail',
                            'E-mail inválido'
                          ]}
                          fullWidth
                          label="E-mail"
                          onInput={stringUtils.toInputLowerCase}
                          value={props.orgao.email || ''}
                          className={classes.textField}
                          onChange={handleChange('email')}
                          id="email"
                          name="email"
                          autoComplete="email"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="telefone"
                          label="Telefone"
                          margin="normal"
                          onChange={handleChange('telefone')}
                          value={phoneMask(props.orgao.telefone) || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="celular"
                          label="Celular"
                          margin="normal"
                          onChange={handleChange('celular')}
                          value={phoneMask(props.orgao.celular) || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {params.id &&
                    <>
                      <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                        <Divider />
                      </Grid>
                      <Grid container lg={12} md={12} xl={12} xs={12} spacing={2}>
                        <Grid container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 7 }} spacing={2} >
                          <Grid item lg={6} md={6} xl={6} xs={12}>
                            <Typography component="h4" variant="subtitle" align="right" >
                              Cadastrar e vincular usuário ao órgão
                            </Typography>
                          </Grid>
                          <AccessControl
                            rule={'usuariosOrgaos.create'} //permissão necessária para acessar conteúdo
                            yes={() => (
                              <Grid item lg={4} md={4} xl={4} xs={12} align="left">
                                <Button style={{ marginLeft: '15px', backgroundColor: '#000' }}
                                  name="btnUsuarios"
                                  id="btnUsuarios"
                                  className="btn"
                                  variant="contained"
                                  component='a'
                                  onClick={() => handleOpenModal()}>Cadastrar usuário&nbsp;<FontAwesomeIcon icon={faUserPlus} />
                                </Button>
                              </Grid>
                            )}
                            no={() => <AccessDenied />}
                          />
                          <Grid container spacing={2} style={{ marginTop: 6, display: 'flex', flexDirection: 'row', marginLeft: 15, marginRight: 15, justifyContent: 'center' }} align="center">
                            {props.orgao.usuariosOrgaos != null && props.orgao.usuariosOrgaos.length > 0 ?
                              props.orgao.usuariosOrgaos.map(n => {
                                return (
                                  (n.id !== '' && n.excluido != true ?
                                    <Grid item lg={3} md={3} xl={3} xs={12} style={{ marginBottom: 2 }}>
                                      <Card>
                                        <CardContent>
                                          <Typography variant="h6" component="div">
                                            {(n.usuario ? n.usuario.nome.toUpperCase() : n.idUsuario)}
                                          </Typography>
                                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {(n.usuario ? n.usuario.email : '')}
                                          </Typography>
                                          <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            {(n.usuario ? n.usuario.idTipoUsuario == TIPO_USUARIO.ADMIN ? 'ADMINISTRADOR' : n.usuario.idTipoUsuario == TIPO_USUARIO.GESTOR_ORGAO ? 'GESTOR ÓRGÃO PÚBLICO' : n.usuario.idTipoUsuario == TIPO_USUARIO.PRESTADOR ? 'PRESTADOR DE SERVIÇO' : n.usuario.idTipoUsuario == TIPO_USUARIO.MOTORISTA ? 'MOTORISTA' : n.usuario.idTipoUsuario == TIPO_USUARIO.FISCAL ? 'FISCAL' : TIPO_USUARIO.USUARIO_ORGAO ? 'USUÁRIO ÓRGÃO PÚBLICO' : '' : '')}
                                          </Typography>
                                          <Tooltip
                                            title="Excluir usuário"                                          >
                                            <Button sx={{ fontWeigth: 400, marginLeft: 7, marginRight: 7 }} onClick={() => handleRemoveOnList(n.idUsuario)} key={'button' + n.id}>
                                              <FontAwesomeIcon icon={faTrashAlt} style={{ color: '#000', fontSize: 17 }} />
                                            </Button>
                                          </Tooltip>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                    : null)
                                );
                              })
                              : null
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                        <Divider />
                      </Grid>
                      <Grid container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }} spacing={2} >
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <Typography component="h4" variant="subtitle" align="right" >
                            Cadastrar e vincular veículo ao órgão
                          </Typography>
                        </Grid>
                        <AccessControl
                          rule={'veiculos.create'} //permissão necessária para acessar conteúdo
                          yes={() => (
                            <Grid item lg={4} md={4} xl={4} xs={12} align="left">
                              <Button style={{ marginLeft: '15px', backgroundColor: '#000', alignItems: 'center' }}
                                name="btnVeiculos"
                                id="btnVeiculos"
                                className="btn"
                                variant="contained"
                                component='a'
                                onClick={() => handleOpenModalVeiculo()}>Cadastrar veículo&nbsp;<DirectionsCarIcon />
                              </Button>
                            </Grid>
                          )}
                          no={() => <AccessDenied />}
                        />
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <AccessControl
                            rule={'veiculos.list'} //permissão necessária para acessar conteúdo
                            yes={() => (
                              <div>
                                <Grid item xl={3} lg={3} md={6} xs={12} style={{ marginTop: 5 }}>
                                  <Input
                                    id="term"
                                    label="term"
                                    placeholder="Filtro"
                                    value={term}
                                    onChange={handleChangeTerm('term')}
                                    fullWidth
                                  />
                                </Grid>
                                <br />
                                <TableContainer component={Paper}>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Placa</TableCell>
                                        <TableCell>Marca</TableCell>
                                        <TableCell>Modelo</TableCell>
                                        <TableCell>Sub Órgão</TableCell>
                                        <TableCell align="right">Ações</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {undefined !== veiculo && veiculo.length
                                        ? veiculo.map((n) => {
                                          return (
                                            <TableRow hover key={n.id}>
                                              <TableCell component="th" scope="row">
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><span style={{ lineHeight: '1.5em' }}>{n.placa ? placaMask(n.placa) : ''}</span> {n.telemetria ?
                                                  <Tooltip title="Possui telemetria">
                                                    <Tooltip>
                                                      <IntegrationInstructionsOutlinedIcon style={{
                                                        fontSize: '1.5em',
                                                        marginLeft: 1
                                                      }} />
                                                    </Tooltip>
                                                  </Tooltip> : null}
                                                  {n.gestaoAbastecimento ?
                                                    <Tooltip title="Possui gestão de abastecimento">
                                                      <Tooltip>
                                                        <LocalGasStationOutlinedIcon style={{
                                                          fontSize: '1.5em',
                                                          marginLeft: 1
                                                        }} />
                                                      </Tooltip>
                                                    </Tooltip> : null}
                                                </div>
                                              </TableCell>
                                              <TableCell component="th" scope="row">
                                                {n.marca ? n.marca.nome.toUpperCase() : ''}
                                              </TableCell>
                                              <TableCell component="th" scope="row">
                                                {n.modelo ? n.modelo.toUpperCase() : ''}
                                              </TableCell>
                                              <TableCell component="th" scope="row">
                                                {n.subOrgao ? n.subOrgao.nome.toUpperCase() : ''}
                                              </TableCell>
                                              <TableCell align="right">
                                                <AccessControl
                                                  rule={'veiculos.edit'} //permissão necessária para acessar conteúdo
                                                  yes={() => (
                                                    <IconButton
                                                      aria-label="Edit"
                                                      component="a"
                                                      onClick={() => handleOpenModalVeiculo(n.id)}
                                                      size="small"
                                                    >
                                                      <Tooltip title={'Editar'}>
                                                        <Tooltip >
                                                          <EditIcon />
                                                        </Tooltip>
                                                      </Tooltip>
                                                    </IconButton>
                                                  )}
                                                />
                                                <AccessControl
                                                  rule={'veiculos.delete'} //permissão necessária para acessar conteúdo
                                                  yes={() => (
                                                    <IconButton
                                                      aria-label="Delete"
                                                      onClick={() => handleClick(n.id)}
                                                      size="small"
                                                    >
                                                      <Tooltip title="Excluir">
                                                        <DeleteIcon />
                                                      </Tooltip>
                                                    </IconButton>
                                                  )}
                                                />
                                              </TableCell>
                                            </TableRow>
                                          )
                                        })
                                        :
                                        (
                                          isLoading ?
                                            Array.apply(null, { length: 5 }).map((e, i) => (
                                              <TableRow hover>
                                                <TableCell component="th" scope="row">
                                                  <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                  <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                  <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                  <Skeleton animation="wave" />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                  <Skeleton animation="wave" />
                                                </TableCell>
                                              </TableRow>
                                            ))
                                            :
                                            <TableRow>
                                              <TableCell rowSpan={3} colSpan={5} align="center">Nenhum registro encontrado</TableCell>
                                            </TableRow>
                                        )
                                      }
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <TablePagination
                                  component="div"
                                  count={totalRegistros}
                                  page={page}
                                  labelRowsPerPage="Linhas por página"
                                  labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
                                  onPageChange={handleChangePage}
                                  rowsPerPage={limit}
                                  onRowsPerPageChange={handleChangeLimit}
                                />
                              </div>
                            )}
                            no={() => <AccessDenied />}
                          />
                        </Grid>
                      </Grid>
                    </>
                  }
                </CardContent>
                <Divider />
                <Typography style={{ fontSize: "10px", paddingLeft: "16px", marginTop: "12px" }}>
                  * Campos obrigatórios
                </Typography>
                <CustomCardActions>
                  <CustomGridButtonsAction item lg={12} md={12} xl={12} xs={12}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => history.push('/Orgaos')}
                    >
                      Cancelar
                    </CustomButton>
                    {orgao.id && authActions.isGestor() ?
                      <>
                        <CustomButton
                          variant="outlined"
                          dark={false}
                          onClick={handleRecusaCadastroOrgao}
                          disabled={props.orgao.cadastroLiberado == false || props.orgao.isLoading}
                          startIcon={<CloseIcon />}
                          endIcon={props.orgao.isLoading && <CircularProgress size={24} />}
                        >
                          {props.orgao.cadastroLiberado == false ? 'Acesso recusado' : props.orgao.isLoading ? 'Recusando acesso...' : 'Recusar acesso'}
                        </CustomButton>
                        <CustomButton
                          variant="outlined"
                          dark={false}
                          onClick={handleAprovaCadastroOrgao}
                          disabled={props.orgao.cadastroLiberado == true || props.orgao.isLoading}
                          startIcon={<DoneIcon />}
                          endIcon={props.orgao.isLoading && <CircularProgress size={24} />}
                        >
                          {props.orgao.cadastroLiberado == true ? 'Acesso liberado' : props.orgao.isLoading ? 'Liberando acesso...' : 'Liberar acesso'}
                        </CustomButton>
                      </>
                      : null}
                    <CustomButton
                      variant="contained"
                      dark={true}
                      type="submit"
                      disabled={props.orgao.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.orgao.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.orgao.isLoading ? 'Atualizando órgão...' : 'Atualizar') : (props.orgao.isLoading ? 'Salvando órgão...' : 'Salvar')}
                    </CustomButton>
                  </CustomGridButtonsAction>
                </CustomCardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid >

      {
        (modalUsuario ?
          <UsuarioEditModal toggle={toggleUsuario} modal={modalUsuario} props={props} idorgao={params.id}
          />
          :
          null
        )
      }

      {
        (modalVeiculo ?
          <VeiculoOrgaoEditModal toggle={toggleVeiculo} modal={modalVeiculo} props={props} idorgao={params.id} orgao={orgao} idveiculo={idVeiculo}
          />
          :
          null
        )
      }

      {
        (!params.id && modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.CADASTRO_ORGAO_ADMIN}
          />
          :
          null
        )
      }

      {
        (params.id && modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.EDICAO_ORGAO}
          />
          :
          null
        )
      }
    </Page >
  )
}

OrgaoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedOrgaoEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(OrgaoEdit))
)
export { connectedOrgaoEditPage as OrgaoEdit }
