import React, { useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { withRouter } from 'react-router-dom';
import { PDFViewer, Document, Page, View, Text, StyleSheet } from '@react-18-pdf/renderer';
import { Header, Footer } from '../../_reports';
import { placaMask } from '../../utils'
import {
  relatorioAction
} from '../../_actions'
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import moment from 'moment'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    fontSize: 7,
    fontFamily: 'Roboto-Regular',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1
  },
  header: {
    padding: 5,
    fontFamily: 'Roboto-Bold',
    textAlign: 'left'
  },
  headerValor: {
    padding: 5,
    fontFamily: 'Roboto-Bold',
    textAlign: 'left'
  },
  headerAgrupamento: {
    padding: 4,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center'
  },
  total: {
    padding: 4,
    fontFamily: 'Roboto-Bold',
    textAlign: 'right'
  },
  cell: {
    padding: 3,
    textAlign: 'left'
  },
  cellDatas: {
    padding: 3,
    fontSize: 10,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    fontFamily: 'Roboto-Bold'
  }
});

const CustomPage = styled(Page)(() => ({
  padding: 20,
  paddingBottom: 25,
  height: '100%',
  width: '100%'
}));

const RelatorioServicoRealizadoPrint = (props) => {

  const dispatch = useDispatch()
  const [ready, setReady] = React.useState(false);
  const { relatorio } = props.relatorio
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {

  }, [props.relatorio]);

  const filterModel = {
    IdTipoServico: queryParams.get('IdTipoServico'),
    RazaoSocial: queryParams.get('RazaoSocial'),
    Cnpj: queryParams.get('Cnpj'),
    PlacaVeiculo: queryParams.get('PlacaVeiculo'),
    Page: queryParams.get('Page'),
    MarcaVeiculo: queryParams.get('MarcaVeiculo'),
    DataInicio: queryParams.get('DataInicio'),
    DataFim: queryParams.get('DataFim'),
    IdSubOrgao: queryParams.get('IdSubOrgao'),
    Emergencia: queryParams.get('Emergencia'),
    Garantia: queryParams.get('Garantia'),
    IdOrgao: queryParams.get('IdOrgao'),
    Limit: queryParams.get('Limit')
  };

  useEffect(() => {
    const modifiedFilterModel = {
      ...filterModel,
      Limit: 9999999
    };

    dispatch(relatorioAction.getRelatorioServicosRealizados(modifiedFilterModel, true));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 1100);
  }, [])

  const onRendered = () => {
  };

  return (
    (ready &&
      <div style={{ width: '100%', height: '100%' }}>
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document onRender={onRendered} width='100%'>
            <CustomPage orientation="landscape" size="A4" style={styles.page} wrap={true} >
              <Header
                title='Relatório Serviços Realizados'
                info={<Text>ROTOR - FABRICAÇÃO, MANUTENÇÃO, REPARAÇÃO E FORNECIMENTO LTDA - 40.691.238/0001-25</Text>}
              />
              {
                relatorio.length > 0 ?
                  Object.entries(
                    relatorio.reduce((acc, item) => {
                      // Agrupa os itens por SubOrgao
                      const key = item.subOrgao || 'Sem Sub Órgão Vinculado'; // SubOrgao pode ser vazio
                      if (!acc[key]) acc[key] = [];
                      acc[key].push(item);
                      return acc;
                    }, {})
                  ).map(([subOrgao, items], groupIndex) => {
                    // Calcula o total do grupo
                    const total = items.reduce((sum, item) => sum + (item.valor || 0), 0);
                    return (
                      <View key={`group-${groupIndex}`} style={{ marginBottom: 5, marginTop: groupIndex == 0 ? 60 : 0 }}>
                        {/* Cabeçalho do SubOrgao */}
                        {groupIndex === 0 && ((filterModel.DataInicio && filterModel.DataInicio != "") || (filterModel.DataFim && filterModel.DataFim != "")) ? (
                          <View style={[styles.row]} key={0}>
                            <View style={[styles.cellDatas, { width: 850 }]}>
                              {filterModel.DataInicio ? <Text>Data Início: {moment(filterModel.DataInicio).format("DD/MM/yyyy")}</Text> : null}
                              {filterModel.DataFim ? <Text>Data Fim: {moment(filterModel.DataFim).format("DD/MM/yyyy")}</Text> : null}
                            </View>
                          </View>
                        ) : null}
                        <View style={[styles.row, { backgroundColor: '#ddd', padding: 5 }]} wrap={false}>
                          <Text style={[styles.headerAgrupamento]}>{subOrgao.toUpperCase()}</Text>
                        </View>
                        <View style={[styles.row]} key={`header-${groupIndex}`} wrap={false}>
                          <View style={[styles.header, { width: 100 }]}>
                            <Text>DATA</Text>
                          </View>
                          <View style={[styles.header, { width: 250 }]}>
                            <Text>SERVIÇO REALIZADO</Text>
                          </View>
                          <View style={[styles.header, { width: 100 }]}>
                            <Text>TIPO DE SERVIÇO</Text>
                          </View>
                          <View style={[styles.header, { width: 200 }]}>
                            <Text>RAZÃO SOCIAL</Text>
                          </View>
                          <View style={[styles.header, { width: 70 }]}>
                            <Text>PLACA VEÍCULO</Text>
                          </View>
                          <View style={[styles.header, { width: 70 }]}>
                            <Text>MARCA VEÍCULO</Text>
                          </View>
                          <View style={[styles.headerValor, { width: 60 }]}>
                            <Text>VALOR</Text>
                          </View>
                        </View>

                        {/* Renderiza os itens dentro do SubOrgao */}
                        {items.map(n => (
                          <>
                            <View style={[styles.row]} key={n.id} wrap={false}>
                              <View style={[styles.cell, { width: 100 }]}>
                                <Text>{n.dataOrdemServico ? moment(n.dataOrdemServico).format('DD/MM/yyyy') : ''}</Text>
                              </View>
                              <View style={[styles.cell, { width: 250 }]}>
                                <Text># {n.idOrdemServico} {n.tituloOrdemServico ? n.tituloOrdemServico.toUpperCase() : ''}</Text>
                              </View>
                              <View style={[styles.cell, { width: 100 }]}>
                                <Text>{n.tipoServico ? n.tipoServico.toUpperCase() : ''}</Text>
                              </View>
                              <View style={[styles.cell, { width: 200 }]}>
                                <Text>{n.nomeEmpresa ? n.nomeEmpresa.toUpperCase() : ''}</Text>
                              </View>
                              <View style={[styles.cell, { width: 70 }]}>
                                <Text>{n.placaVeiculo ? placaMask(n.placaVeiculo) : ''}</Text>
                              </View>
                              <View style={[styles.cell, { width: 70 }]}>
                                <Text>{n.marcaVeiculo ? n.marcaVeiculo.toUpperCase() : ''}</Text>
                              </View>
                              <View style={[styles.cell, { width: 60, textAlign: 'right' }]}>
                                <Text>{n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}</Text>
                              </View>
                            </View>
                            {n.garantia && n.ordemServicoOrcamentoAprovado.observacoesGarantia != null &&
                              n.ordemServicoOrcamentoAprovado.observacoesGarantia != "" ?
                              <View style={[styles.row]} key={0}>
                                <View style={[styles.cell, { width: 850 }]}>
                                  <Text><strong>DETALHES DA GARANTIA: </strong>{n.ordemServicoOrcamentoAprovado.observacoesGarantia.toUpperCase()}</Text>
                                </View>
                              </View>
                              : null}
                          </>
                        ))}
                        {/* Linha com o total do SubOrgao */}
                        <View style={[styles.row, { backgroundColor: '#f5f5f5', marginTop: 0 }]}>
                          <View style={[styles.cell, { width: 850, textAlign: 'right', padding: 5 }]}>
                            <Text style={[styles.total]}>TOTAL {subOrgao.toUpperCase()}: R$ {total.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</Text>
                          </View>
                        </View>
                      </View>
                    );
                  })
                  : (
                    <View style={[styles.row]} key={0}>
                      <View style={[styles.cell, { width: 850 }]}>
                        <Text>Nenhum registro encontrado</Text>
                      </View>
                    </View>
                  )
              }
              <Footer />
            </CustomPage>
          </Document>
        </PDFViewer>
      </div >
    )
  );
}

const mapStateToProps = (state) => {
  return state;
};

const connectedRelatorioServicoRealizadoPrintPage = withRouter(connect(mapStateToProps, null, null)(RelatorioServicoRealizadoPrint));
export { connectedRelatorioServicoRealizadoPrintPage as RelatorioServicoRealizadoPrint };
