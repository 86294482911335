import React, { useState } from 'react'
import { Grid, Typography, Button, IconButton, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { history } from '../utils'
import HelpIcon from '@mui/icons-material/Help';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import { HelpModal } from '../components';
import clsx from 'clsx'
import { AccessControl } from '../components/Utils/AccessControl'

const useStyles = makeStyles(() => ({
  root: {}
}))

const TitleContent = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid item>
        <Typography component="h3" gutterBottom variant="overline">
          {props.subTitle}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography component="h1" variant="h5">
            {props.title}
          </Typography>
          <IconButton
            aria-label="Ajuda"
            component="a"
            onClick={() =>
              handleOpenModalHelp()
            }
            size="small"
          >
            <Tooltip title={'Ajuda'}>
              <Tooltip>
                <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
              </Tooltip>
            </Tooltip>
          </IconButton>
        </div>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={11} md={11} xl={11} xs={12} align="right" >
          {props.exibeBotaoFrota ?
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: '#000', color: '#FFF' }}
              startIcon={<AirportShuttleIcon />}
              onClick={() => history.push('/listVeiculosTelemetria?Page=0&Limit=10&DataInicio=')}
            >
              Ver frota veicular
            </Button>
            : null}
        </Grid>
        <Grid item lg={1} md={1} xl={1} xs={12} align="center">
          <AccessControl
            rule={props.rule} //permissão necessária para acessar conteúdo
            yes={() => (
              <Grid item>
                <Grid item xs={6} align="right">
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#000' }}
                    onClick={() => history.push(`${props.href}`)}
                  >
                    {props.ordemServico ? 'NOVA' : 'NOVO'}
                  </Button>
                </Grid>
              </Grid>
            )}
          />
        </Grid>
      </Grid>
      <br></br>
      {props.length !== undefined && props.length !== 0 ? (
        <Grid item>
          <Typography variant="body2" align="left" gutterBottom>
            Total de dados encontrados: <b>{props.length}</b>
          </Typography>
        </Grid>
      ) : (
        <Typography variant="body2" align="left" gutterBottom>
          <b>{'Nenhum dado encontrado.'}</b>
        </Typography>
      )}
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={props.telaManual}
          />
          :
          null
        )
      }
    </div>
  )
}

TitleContent.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  rule: PropTypes.string,
  telaManual: PropTypes.string.isRequired,
  exibeBotaoFrota: PropTypes.bool,
  ordemServico: PropTypes.bool
}
export default TitleContent
