import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import CustomButton from '../../components/CustomButton/CustomButton'
import OrdemServicoOcorrenciaChat from './ordemServicoOcorrenciaChat.component'
import clsx from 'clsx'
import HelpIcon from '@mui/icons-material/Help';
import { styled } from '@mui/material/styles';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UndoIcon from '@mui/icons-material/Undo';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import getInitials from 'utils/getInitials'
import { toast } from 'react-toastify'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BuildIcon from '@mui/icons-material/Build';
import CheckIcon from '@mui/icons-material/Check';
import DoneIcon from '@mui/icons-material/Done';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CloseIcon from '@mui/icons-material/Close';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useConfirm } from 'material-ui-confirm'
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import Autocomplete from '@mui/lab/Autocomplete';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import Stars from '../../components/Stars/Stars';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { OrdemServicoMotivoModal } from 'views/OrdensServicoOrcamentos';
import { OrdemServicoReverterStatusMotivoModal } from 'views/OrdensServico';
import { OrdemServicoDeclinarOrcamentoMotivoModal } from 'views/OrdensServico';
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Chip,
  CircularProgress,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Modal,
  Rating,
  Stepper,
  Step,
  StepLabel,
  Switch,
  Tab,
  Tabs,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TextField,
  Typography,
  Tooltip
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  authActions,
  ordemServicoAction,
  ordemServicoOrcamentoAction,
  veiculoAction,
  relatorioAction,
  tipoServicoAction,
  tipoFotoAction,
  usuarioAction,
  orgaoAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { uploadImage } from '_services'
import { MANUAIS_CATEGORIAS, STATUS_SERVICO, errorsUploadImage, getNomeEvento, getNomeUltimoEvento, isWinner, isRefuseByEmpresa } from 'utils'
import { history, stringUtils } from '../../utils'
import { Page, ImageUploader, MapContainer } from 'components'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isEmpty } from 'lodash'
import SaveIcon from '@mui/icons-material/Save';
import { OrdemServicoFinanceiroEdit } from './ordemServicoFinanceiroEdit.component'
import OrdemServicoSummaryCard from './ordemServicoSummaryCard.component'
import { RedeCredenciadaOuMecanicoDeliveryModal } from './redeCredenciadaOuMecanicoDeliveryModal.component';
import { OrdemServicoModalEventos } from './ordemServicoModalEventos.component';
import { HelpModal, EditorHeader } from '../../components';
import { OrdemServicoModalConcluirServico } from './ordemServicoModalConcluirServico.component';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundcolor: "#000"
    }
  },
  img: {
    outline: "none"
  }
})

const SwitchStyled = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#000',
    }
  }
}));

const CustomCardActions = styled(CardActions)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    textAlign: 'center'
  },
}));

const CustomGridButtonsAction = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'right',
  flexDirection: 'row',
  textAlign: 'right',
  justifyContent: 'right',
  [theme.breakpoints.down('sm')]: {
    paddingTop: 3,
    display: 'block',
    textAlign: 'center'
  },
}));

const CustomStepper = styled(Stepper)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    justifyContent: 'center'
  },
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242, 214, 71) 30%,rgb(0, 0, 0) 70%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242, 214, 71) 30%,rgb(0, 0, 0) 70%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242, 214, 71) 30%, rgb(0, 0, 0) 70%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242, 214, 71) 30%, rgb(0, 0, 0) 70%)',
  }),
}));

function isEditable(e) {
  if (e == '2' || e == '3' || e == '4' || e == '5' || e == '6' || e == '7') {
    return false
  }
  else return true
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const OrdemServicoEdit = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));



  const { classes, ordemServico, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [value, setValue] = useState(null);
  const [steps, setSteps] = useState([
    { tipo: null, nome: 'EM LANÇAMENTO' },
    { tipo: 1, nome: 'PENDENTE' },
    { tipo: 2, nome: 'RECEBENDO ORÇAMENTOS' },
    { tipo: 3, nome: 'EM EXECUÇÃO' },
    { tipo: 4, nome: 'RECUSADA/EXCLUÍDA' },
    { tipo: 5, nome: 'CONCLUÍDA' },
    { tipo: 6, nome: 'AVALIADA' },
    { tipo: 7, nome: 'VERIFICADA' },
    { tipo: 8, nome: 'DESERTO' },
    { tipo: 9, nome: 'SEM_SUCESSO' }
  ]);

  const idEmpresa = authActions.isEmpresa() ? props.authentication?.user?.usuariosEmpresas[0]?.idEmpresa : 0

  const currentDate = moment().format("yyyy-MM-DD");

  const [valueVeiculo, setValueVeiculo] = useState(null);
  const [inputValueVeiculo, setInputValueVeiculo] = useState('');

  const [valueTipoServico, setValueTipoServico] = useState(null);
  const [inputValueTipoServico, setInputValueTipoServico] = useState('');

  const [valueTipoFoto, setValueTipoFoto] = useState(null);
  const [inputValueTipoFoto, setInputValueTipoFoto] = useState('');

  const location = useLocation();

  const isCobranca = location.state?.isCobranca ? location.state.isCobranca : false;
  const searchParams = new URLSearchParams(location.search);

  //parâmetro que define o tipo de serviço a ser carregado na combo da O.S
  const tipoServicoParametroUrl = searchParams.get('TipoServico');

  const [open, setOpen] = useState(false)

  const [id, setId] = useState(0);

  //ORÇAMENTOS QUE ESTÃO APTOS A SEREM APROVADOS OU NÃO
  //NÃO PODEM SER DECLÍNIO
  const orcamentosValidos = ordemServico.ordensServicosOrcamentos?.filter((n) => n.statusOrcamento !== 2 && n.declinio != true);

  //ORÇAMENTOS DECLINADOS
  const orcamentosDeclinados = ordemServico.ordensServicosOrcamentos?.filter((n) => n.declinio == true);

  //ORÇAMENTOS RECUSADOS
  const orcamentosRecusados = ordemServico.ordensServicosOrcamentos?.filter((n) => n.declinio != true && n.statusOrcamento == 2);

  const [images, setImages] = useState([])
  const [imagem, setImagem] = useState('')
  const [rate, setRate] = useState(0);
  const [hover, setHover] = useState(-1);
  const [tabValue, setTabValue] = useState(0);

  const { veiculo } = useSelector(
    (state) => state.veiculo)

  const { tipoServico } = useSelector(
    (state) => state.tipoServico)

  const { tipoFoto } = useSelector(
    (state) => state.tipoFoto)

  const { orgao } = useSelector(
    (state) => state.orgao)

  const optionsVeiculos = veiculo && veiculo.length > 0 && veiculo?.map(u => ({ id: u.value, placa: u.text }));

  const optionsTiposServicos = tipoServicoParametroUrl && tipoServicoParametroUrl != 0 &&
    tipoServicoParametroUrl != "" ? [{ id: tipoServico.find(t => t.value == tipoServicoParametroUrl).value, nome: tipoServico.find(t => t.value == tipoServicoParametroUrl).text }] : tipoServico?.map(u => ({ id: u.value, nome: u.text }));
  const optionsTiposFotos = tipoFoto?.map(u => ({ id: u.value, nome: u.text }));

  useEffect(() => {
    if (tipoServicoParametroUrl && tipoServicoParametroUrl != "" && tipoServicoParametroUrl != 0 && tipoServico && tipoServico.length > 0) {
      setValueTipoServico({ id: tipoServico.find(t => t.value == tipoServicoParametroUrl).value, nome: tipoServico.find(t => t.value == tipoServicoParametroUrl).text })
    }
  }, [tipoServicoParametroUrl])

  const totalSteps = () => {
    let stepsCount = ordemServico.eventos.length;

    // Adiciona passos extras com base no estado da O.S.
    if (ordemServico.ultimoEvento < STATUS_SERVICO.RECUSADO) {
      stepsCount += 1; // Adiciona CONCLUIDO
    }
    if (ordemServico.ultimoEvento < STATUS_SERVICO.AVALIADO) {
      stepsCount += 1; // Adiciona AVALIADO
    }
    if (ordemServico.ultimoEvento < STATUS_SERVICO.VERIFICADO) {
      stepsCount += 1; // Adiciona VERIFICADO
    }

    return stepsCount;
  };

  const isHorizontal = totalSteps() <= 6;

  const [, forceUpdate] = useState(0);
  const [ready, setReady] = useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState("false");

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [modalEventos, setModalEventos] = useState(false);
  const toggleModalEventos = () => setModalEventos(!modalEventos);

  const [modalOrcamentoMotivo, setModalOrcamentoMotivo] = useState(false);
  const toggleModalOrcamentoMotivo = () => setModalOrcamentoMotivo(!modalOrcamentoMotivo);
  const [orcamento, setOrcamento] = useState(null);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const [modalConcluirOS, setModalConcluirOs] = useState(false);
  const toggleModalConcluirOs = () => setModalConcluirOs(!modalConcluirOS);

  const [modalMotivoReverterStatus, setModalMotivoReverterStatus] = useState(false);
  const toggleModalMotivoReverterStatus = () => setModalMotivoReverterStatus(!modalMotivoReverterStatus);

  const [modalMotivoDeclinar, setModalMotivoDeclinar] = useState(false);
  const toggleModalMotivoDeclinar = () => setModalMotivoDeclinar(!modalMotivoDeclinar);

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const removeImage = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Não'
    }).then(() => {
      setImagem(null)
    })
  }

  const handleOpenModalMotivo = (n) => {
    setOrcamento(n);
    toggleModalOrcamentoMotivo();
  }

  const handleOpenReverterStatusModalMotivo = (n) => {
    toggleModalMotivoReverterStatus();
  }

  const handleOpenModalMotivoDeclinarOrcamento = (n) => {
    toggleModalMotivoDeclinar();
  }

  const handleReverterAprovacaoOrcamento = () => {
    confirm({
      title: 'Você deseja reverter a aprovação do orçamento dessa ordem de serviço?',
      description: 'Essa é uma situação crítica, só faça se tiver total certeza! A ordem de serviço voltará para o status de \"recebendo orçamentos\".',
      disabled: props.ordemServicoOrcamento.isLoading,
      confirmationText: props.ordemServicoOrcamento.isLoading ? 'Revertendo...' : 'Sim, reverter!',
      cancellationText: 'Não!'
    }).then(() => {
      handleOpenReverterStatusModalMotivo();
    })
  }

  const handleDeclinarOrcamento = (id) => {
    confirm({
      title: 'Você deseja declinar o envio de orçamentos para esta ordem de serviço?',
      description: 'Essa é uma situação crítica, só faça se tiver total certeza! Você não será permitido posteriormente de enviar um orçamento',
      disabled: props.ordemServicoOrcamento.isLoading,
      confirmationText: props.ordemServicoOrcamento.isLoading ? 'Declinando...' : 'Sim, declinar!',
      cancellationText: 'Não!'
    }).then(() => {
      handleOpenModalMotivoDeclinarOrcamento();
    })
  }

  const handleDevolveOrdemServicoRevisao = (id) => {
    confirm({
      title: 'Você deseja devolver a ordem de serviço para revisão?',
      description: 'Essa operação é irreversível',
      disabled: props.ordemServico.isLoading,
      confirmationText: props.ordemServico.isLoading ? 'Retornando status...' : 'Sim, retornar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(ordemServicoAction.editOrdemServicoDevolveParaRevisao(id))
    })
  }
  function ColorlibStepIcon(props) {
    const { active, completed, className, evento, ultimoEvento } = props;

    // Verifica se o evento já ocorreu com base no número do evento e no último evento ocorrido
    const eventoOcorreu = evento <= ultimoEvento;

    const icons = {
      null: <ListAltIcon />,
      1: <PendingActionsRoundedIcon />,
      2: <CurrencyExchangeIcon />,
      3: <BuildIcon />,
      4: <CancelOutlinedIcon />,
      5: <CheckCircleOutlineIcon />,
      6: <StarOutlineIcon />,
      7: <EventAvailableIcon />
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active, eventoOcorreu }} className={className}>
        {icons[evento]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    evento: PropTypes.number,
    ultimoEvento: PropTypes.number // Adicione a propriedade para o número do último evento ocorrido
  };


  useEffect(() => {
    if (authActions.isGestor()) {
      if (isCobranca == true) {
        setTabValue(3);
      }
    } else if (authActions.isEmpresa()) {

    } else if (authActions.isMotorista()) {
      setTabValue(1);
    } else if (authActions.isFiscal()) {
      setTabValue(0);
    }

    const interval = setInterval(() => {
      setReady(true)
    }, 1500);
    return () => clearInterval(interval);
  }, [])

  const handleImage = (value) => {
    setImage(value);
    setOpenImage(true);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  function isMenorPreco(valor) {
    var min = props.ordemServico?.ordensServicosOrcamentos?.filter(function (orcamento) {
      return orcamento.valor != null; // Filtrar os orçamentos com valor diferente de null
    })?.reduce(function (prev, curr) {
      return prev.valor < curr.valor ? prev : curr;
    });

    if (min && min.valor != null && min.valor != 0 && min.valor != '' && min.valor == valor) {
      return true;
    } else {
      return false;
    }
  }

  const handleOpenArquivo = (link) => {
    window.open(link, '_blank');
  }

  const labels = {
    1: 'Péssimo',
    2: 'Ruim',
    3: 'Médio',
    4: 'Bom',
    5: 'Excelente',
  };

  useEffect(() => {

    dispatch(ordemServicoAction.clear())
    dispatch(tipoFotoAction.getTiposFotoSelect())
    dispatch(tipoServicoAction.getTiposServicoSelect())

    if (idOrgao != undefined && idOrgao != '') {
      dispatch(veiculoAction.getVeiculoSelect(idOrgao))
    } else if (props.ordemServico.idOrgao != 0 && props.ordemServico.idOrgao != undefined) {
      dispatch(veiculoAction.getVeiculoSelect(props.ordemServico.idOrgao))
    }

    dispatch(orgaoAction.getOrgaoSelect())
    dispatch(usuarioAction.getUsuarioSelect())

    if (params.id) {
      dispatch(ordemServicoAction.getOrdemServicoById(params.id))
    } else {
      if (idOrgao == '') {
        toast.error('Selecione um órgão')
        history.push('ordensServico')
      }
      dispatch(ordemServicoAction.clear())
    }

  }, []);


  const handleOpenModal = () => {
    toggleModal();
  }

  const handleOpenModalConcluirOS = () => {
    toggleModalConcluirOs();
  }

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const handleOpenModalEventos = () => {
    toggleModalEventos();
  }

  useEffect(() => {
    if (idOrgao != undefined && idOrgao != '') {
      dispatch(veiculoAction.getVeiculoSelect(idOrgao))
    } else if (props.ordemServico.idOrgao != 0 && props.ordemServico.idOrgao != undefined) {
      dispatch(veiculoAction.getVeiculoSelect(props.ordemServico.idOrgao))
    }
  }, [idOrgao, props.ordemServico.idOrgao])

  useEffect(() => {
    if (props.ordemServico.idUsuario != 0 && params.id != undefined) {
      setValue(props.ordemServico.usuario)
    }
  }, [props.ordemServico.idUsuario])

  useEffect(() => {
    if (props.ordemServico.idVeiculo != 0 && params.id != undefined) {
      setValueVeiculo(props.ordemServico.veiculo)
    }
  }, [props.ordemServico.idVeiculo])

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value)
    }
  }, [value])

  useEffect(() => {
    if (valueVeiculo) {
      handleChangeAutoComplete(valueVeiculo, veiculo, 'veiculo')
    }
  }, [valueVeiculo])

  useEffect(() => {
    if (valueTipoServico) {
      handleChangeAutoComplete(valueTipoServico, tipoServico, 'tipoServico')
    }
  }, [valueTipoServico])

  useEffect(() => {
    if (valueTipoFoto) {
      handleChangeAutoComplete(valueTipoFoto, tipoFoto, 'tipoFoto')
    }
  }, [valueTipoFoto])

  useEffect(() => {
    if (params.id != undefined && props.ordemServico.idTipoServico != 0) {
      setValueTipoServico({
        'id': tipoServico?.find(m => m.value == props.ordemServico.idTipoServico)?.value,
        'nome': tipoServico?.find(m => m.value == props.ordemServico.idTipoServico)?.text
      })
    }
  }, [props.ordemServico.idTipoServico])

  const handleAvaliarOrdemServico = () => {
    let payload = {
      id: params.id,
      avaliacao: rate,
      descritivoAvaliacao: props.ordemServico.descritivoAvaliacao,
      ultimoEvento: props.ordemServico.ultimoEvento,
      idUsuarioAvaliacao: props.ordemServico.idUsuarioAvaliacao,
      idOrgao: props.ordemServico.idOrgao
    }

    if (rate >= 1 && rate <= 5) {
      dispatch(ordemServicoAction.editOrdemServicoAvalia(params.id, payload));
    } else {
      toast.error('Avalie com uma nota de 1 a 5')
    }
  }

  const handleExportExcel = () => {
    let filterModel = {
      Id: params.id
    };
    dispatch(relatorioAction.getRelatorioOrcamentosArquivo(filterModel))
  }

  const handleExportPDF = () => {

    let payload = {
      IdOrdemServico: params.id
    }

    dispatch(relatorioAction.getRelatorioOrcamentos(payload, true));

    window.open('/relatorioOrcamentosOrdemServico', '_blank');
  }

  const handleAddOnList = e => {
    if (props.ordemServico.ordensServicosFotos.filter(ts => ts.id == parseInt(id)).length > 0) {
      toast.error('Erro ao adicionar! Esta foto já existe!')
    }
    else {
      if (valueTipoFoto != null && valueTipoFoto.id != 0) {
        if (imagem && imagem != null) {
          props.ordemServico.ordensServicosFotos.push({
            'idTipoFoto': valueTipoFoto.id,
            'caminhoFoto': imagem,
            'idOrdemServico': params.id
          });

        }
        else {
          toast.error('Carregue uma foto para prosseguir')
        }
      } else {
        toast.error('Selecione um tipo de foto para prosseguir')
      }
      forceUpdate(n => !n);
      setId(0);
      setValueTipoFoto(null);
      setImagem('')
    }
  }

  const onChangeImage = (imageList) => {
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {
        setImagem(response)
        setOpen(false)
      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  const handleChangeAutoComplete = (obj, array, nameArray) => {
    if (obj != null) {
      let selected = array && array.length > 0 && array?.filter(
        (item) => item.value == obj.id
      )

      if (nameArray == 'veiculo') {
        dispatch(veiculoAction.changeDetailsVeiculo(selected[0]))
      } else if (nameArray == 'tipoServico') {
        dispatch(tipoServicoAction.changeDetailsTipoServico(selected[0]))
      } else if (nameArray == 'tipoFoto') {
        dispatch(tipoFotoAction.changeDetailsTipoFoto(selected[0]))
      }

    } else {

    }
  }

  const handleRemoveOnList = caminhoFoto => {
    props.ordemServico.ordensServicosFotos = props.ordemServico.ordensServicosFotos.filter(ts => ts.caminhoFoto != caminhoFoto);
    forceUpdate(n => !n);
  };

  useEffect(() => {
    // forceUpdate(n => !n);
  }, [open, imagem]);

  useEffect(() => {

  }, [ready, props.ordemServico.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(ordemServicoAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      idTipoServico: tipoServicoParametroUrl && tipoServicoParametroUrl != "" && tipoServicoParametroUrl != 0 ? tipoServicoParametroUrl : valueTipoServico ? valueTipoServico.id : props.ordemServico.idTipoServico,
      idVeiculo: valueVeiculo ? valueVeiculo.id : props.ordemServico.idVeiculo,
      idUsuario: props.authentication.user.id,
      data: props.ordemServico.data ? props.ordemServico.data : currentDate,
      idOrgao: idOrgao != '' && idOrgao != 0 && idOrgao != null ? idOrgao : props.ordemServico.idOrgao,
      descritivo: props.ordemServico.descritivo,
      latitude: props.ordemServico.latitude,
      longitude: props.ordemServico.longitude,
      endereco: props.ordemServico.endereco,
      garantia: props.ordemServico.garantia,
      titulo: props.ordemServico.titulo,
      ultimoEvento: props.ordemServico.ultimoEvento,
      emergencia: props.ordemServico.emergencia,
      mecanicoDelivery: props.ordemServico.mecanicoDelivery,
      ordensServicosFotos: props.ordemServico.ordensServicosFotos
    }

    if (params.id) {
      dispatch(ordemServicoAction.editOrdemServicoInfo(payload, params.id, true))
    } else {
      dispatch(ordemServicoAction.createOrdemServico(payload))
    }

  };

  const handleAprovaOrdemServico = () => {
    confirm({
      title: 'Você deseja aprovar esta Ordem de Serviço?',
      description: 'Essa operação é irreversível',
      disabled: props.ordemServico.isLoading,
      confirmationText: props.ordemServico.isLoading ? 'Aprovando Ordem de Serviço...' : 'Sim, aprovar!',
    }).then(() => {
      dispatch(ordemServicoAction.editOrdemServicoAprova(!params.id ? props.ordemServico.id : params.id))
    })
  }

  const handleAprovaOrcamento = (n) => {
    confirm({
      title: 'Você deseja realmente aprovar este Orçamento?',
      description: 'Os demais orçamentos aprováveis recebidos serão automaticamente marcados como recusados',
      disabled: props.ordemServicoOrcamento.isLoading,
      confirmationText: props.ordemServicoOrcamento.isLoading ? 'Aprovando Orçamento...' : 'Sim, aprovar!',
    }).then(() => {
      handleOpenModalMotivo(n, true)
    })
  }

  const handleRecusaOrcamento = (id) => {
    confirm({
      title: 'Você deseja realmente recusar este Orçamento?',
      description: 'Essa operação é irreversível',
      disabled: props.ordemServicoOrcamento.isLoading,
      confirmationText: props.ordemServicoOrcamento.isLoading ? 'Recusando Orçamento...' : 'Sim, recusar!',
    }).then(() => {
      dispatch(ordemServicoOrcamentoAction.editOrdemServicoOrcamentoRecusa(id, params.id))
    })
  }

  const handleRecusaOrdemServico = () => {
    confirm({
      title: 'Você deseja recusar esta Ordem de Serviço?',
      description: 'Essa operação é irreversível',
      disabled: props.ordemServico.isLoading,
      confirmationText: props.ordemServico.isLoading ? 'Recusando Ordem de Serviço...' : 'Sim, recusar!',
    }).then(() => {
      dispatch(ordemServicoAction.editOrdemServicoRecusa(!params.id ? props.ordemServico.id : params.id))
    })
  }

  const handleFinalizaOrdemServico = () => {
    confirm({
      title: 'Você deseja finalizar esta Ordem de Serviço?',
      description: 'Seus dados serão enviados para o fiscal avaliar o serviço realizado',
      disabled: props.ordemServico.isLoading,
      confirmationText: props.ordemServico.isLoading ? 'Finalizando Ordem de Serviço...' : 'Sim, finalizar!',
    }).then(() => {
      handleOpenModalConcluirOS();
    })
  }

  const handleVerificaOrdemServico = () => {
    confirm({
      title: 'Você deseja verificar esta Ordem de Serviço?',
      description: 'O fluxo da ordem de serviço no sistema será concluído',
      disabled: props.ordemServico.isLoading,
      confirmationText: props.ordemServico.isLoading ? 'Verificando Ordem de Serviço...' : 'Sim, verificar!',
    }).then(() => {
      dispatch(ordemServicoAction.editOrdemServicoVerifica(params.id))
    })
  }

  const handleValidaOrdemServico = () => {
    confirm({
      title: 'Você deseja validar esta Ordem de Serviço?',
      description: 'A ordem de serviço terá o seu status alterado para PENDENTE',
      disabled: props.ordemServico.isLoading,
      confirmationText: props.ordemServico.isLoading ? 'Validando Ordem de Serviço...' : 'Sim, validar!',
    }).then(() => {
      dispatch(ordemServicoAction.editOrdemServicoValida(params.id))
    })
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Nova Ordem de Serviço'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Ordem de Serviço'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }

  function SegHeaderOrcamentoAprovado() {
    return (<Typography variant="h6" align="center">
      <b>{'Orçamento aprovado para a Ordem de Serviço'}</b>
    </Typography>)
  }

  function SegHeaderOrcamentoRecusado() {
    return (<Typography variant="h6" align="left">
      <b>{'Orçamento(s) recusado(s) para a Ordem de Serviço'}</b>
    </Typography>)
  }

  function SegHeaderOrcamentDeclinado() {
    return (<Typography variant="h6" >
      <b>{'Declínios enviados por empresas que não poderão realizar o serviço'}</b>
    </Typography >)
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Ordem de Serviço - Editar Ordem de Serviço'
          : 'Gerência de Ordem de Serviço - Nova Ordem de Serviço'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          {(params.id || props.ordemServico.id) &&
            <OrdemServicoSummaryCard idOrdemServico={params.id} latitude={props.ordemServico.latitude} longitude={props.ordemServico.longitude} />
          }
          <Grid item md={12} xs={12}>
            <Card>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} variant="scrollable" scrollButtons="auto" onChange={handleChangeTab}>
                  <Tab label="CADASTRO" {...a11yProps(0)} />
                  {params.id && (authActions.isGestor() || authActions.isPrefeitura()) && <Tab label="OCORRÊNCIAS" {...a11yProps(1)} />}
                  {params.id && (authActions.isGestor() || authActions.isPrefeitura()) && <Tab label="ORÇAMENTOS" {...a11yProps(2)} />}
                  {params.id && (authActions.isGestor() || authActions.isPrefeitura()) && <Tab label="FINANCEIRO" {...a11yProps(3)} />}
                  {params.id && (authActions.isPrefeitura() || authActions.isMotorista()) && <Tab label="AVALIAÇÃO" {...a11yProps((authActions.isFiscal() || authActions.isMotorista()) ? 1 : authActions.isPrefeitura() ? 3 : 4)} />}
                  {params.id && authActions.isEmpresa() && [
                    <Tab key="financeiro" label="FINANCEIRO" {...a11yProps(1)} />,
                    <Tab key="orcamentos" label="ORÇAMENTOS" {...a11yProps(2)} />
                  ]}
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>

                <ValidatorForm
                  className={classes.form}
                  ref={formRef}
                  id="formOrdemServico"
                  onSubmit={props.ordemServico.emergencia &&
                    (params.id || props.ordemServico.id) ? handleOpenModal : (event) => handleSubmit(event)
                  }
                >
                  <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <CardHeader
                      subheader="Cadastro de Ordem de Serviço"
                      title={(
                        params.id ?
                          <EditorHeader title='Editar Ordem de Serviço' tabela='OrdemServico' chave={params.id} props={props} />
                          :
                          <InsertText />
                      )}
                    />
                    <Grid item lg={1} md={1} xl={1} xs={12}>
                      <IconButton
                        aria-label="Ajuda"
                        component="a"
                        onClick={() =>
                          handleOpenModalHelp()
                        }
                        size="small"
                      >
                        <Tooltip title={'Ajuda'}>
                          <Tooltip>
                            <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                          </Tooltip>
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <CardContent lg={12} md={12} xl={12} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          id="titulo"
                          label="Título * "
                          disabled={isEditable(ordemServico.ultimoEvento) == false}
                          margin="normal"
                          onInput={stringUtils.toInputUppercase}
                          onChange={handleChange('titulo')}
                          value={props.ordemServico.titulo || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={12} style={{ marginTop: 16 }}>
                        <Autocomplete
                          value={valueTipoServico}
                          onChange={(event, newValue) => {
                            setValueTipoServico(newValue);
                          }}
                          disabled={isEditable(ordemServico.ultimoEvento) == false || tipoServicoParametroUrl}
                          inputValue={inputValueTipoServico}
                          onInputChange={(event, newInputValueTipoServico) => {
                            setInputValueTipoServico(newInputValueTipoServico);
                          }}
                          id="controllable-states-demo"
                          options={optionsTiposServicos}
                          margin="normal"
                          variant="outlined"
                          defaultValue={valueTipoServico}
                          getOptionLabel={(optionTipoServico) => (optionTipoServico ? optionTipoServico.nome : '')}
                          renderInput={(params) => <TextField {...params} label="Tipo do Serviço * " fullWidth />}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          multiline
                          disabled={isEditable(ordemServico.ultimoEvento) == false}
                          minRows={2}
                          InputLabelProps={{
                            shrink: true
                          }}
                          id="descritivo"
                          label="Descritivo *"
                          margin="normal"
                          onInput={stringUtils.toInputUppercase}
                          onChange={handleChange('descritivo')}
                          value={props.ordemServico.descritivo || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <SelectValidator
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          variant="outlined"
                          id="idOrgao"
                          label="Órgão *"
                          margin="normal"
                          value={(idOrgao != 0 && idOrgao != '') ? idOrgao : props.ordemServico.idOrgao}
                          onChange={handleChange('idOrgao')}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          displayEmpty
                          disabled={(idOrgao != null && idOrgao != 0 && idOrgao != '') || isEditable(ordemServico.ultimoEvento) == false}
                          labelId="idOrgao"
                        >
                          <MenuItem disabled value="">
                            <em>Órgão</em>
                          </MenuItem>
                          {!isEmpty(orgao) &&
                            undefined !== orgao &&
                            orgao.length &&
                            orgao.map((row) => (
                              <MenuItem key={row.value} value={row.value}>
                                {row.text}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={12} style={{ paddingTop: 31 }}>
                        <Autocomplete
                          value={valueVeiculo}
                          onChange={(event, newValue) => {
                            setValueVeiculo(newValue);
                          }}
                          disabled={isEditable(ordemServico.ultimoEvento) == false}
                          inputValue={inputValueVeiculo}
                          onInputChange={(event, newInputValueVeiculo) => {
                            setInputValueVeiculo(newInputValueVeiculo);
                          }}
                          id="controllable-states-demo"
                          options={optionsVeiculos}
                          margin="normal"
                          variant="outlined"
                          defaultValue={valueVeiculo}
                          getOptionLabel={(optionVeiculo) => (optionVeiculo ? optionVeiculo.placa : '')}
                          renderInput={(params) => <TextField {...params} label="Veículo *... " fullWidth />}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          type="date"
                          id="data"
                          disabled={isEditable(ordemServico.ultimoEvento) == false}
                          label="Data *"
                          margin="normal"
                          onChange={handleChange('data')}
                          value={props.ordemServico.data ? moment(props.ordemServico.data).format("yyyy-MM-DD") : currentDate}
                          variant="outlined"
                          validators={['required']}
                          InputLabelProps={{
                            shrink: true
                          }}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      {(params.id || props.ordemServico.id) &&
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            label="Status"
                            margin="normal"
                            variant="outlined"
                            value={ordemServico && getNomeUltimoEvento(ordemServico.ultimoEvento)}
                          />
                        </Grid>
                      }
                      <Grid item lg={4} md={4} xl={4} xs={12} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <FormControlLabel
                          control={
                            <SwitchStyled
                              color="default"
                              checked={props.ordemServico.emergencia}
                              onChange={handleChange('emergencia')}
                              disabled={isEditable(ordemServico.ultimoEvento) == false}
                            />
                          }
                          label="Ordem de Serviço Emergencial"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                        <FormControlLabel
                          control={
                            <SwitchStyled
                              color="default"
                              checked={props.ordemServico.garantia}
                              onChange={handleChange('garantia')}
                              disabled={isEditable(ordemServico.ultimoEvento) == false}
                            />
                          }
                          label="Exige garantia"
                        />
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} >
                        <Divider />
                      </Grid>
                      {ready &&
                        <Grid item lg={12} md={12} xl={12} xs={12} style={{ position: 'relative' }}>
                          <Box style={{ display: 'relative', left: 0, right: 0, bottom: 0 }}>
                            <MapContainer latitude={props.ordemServico.latitude} longitude={props.ordemServico.longitude} nome={props.ordemServico.titulo} classes={props.classes} ordemServico={props.ordemServico} idOrdemServico={props.ordemServico.id} isEditable={isEditable} />
                          </Box>
                        </Grid>
                      }
                    </Grid>
                    <CardHeader style={{ marginTop: 315 }} title={
                      <Typography variant="h6" align="center">
                        <b>Fotos</b>
                      </Typography>}>
                    </CardHeader>
                    <Divider />
                    <ValidatorForm
                      className={classes.form}
                      ref={formRef}
                      id="formOrdemServicoFotos"
                    >
                      {isEditable(ordemServico.ultimoEvento) == true &&
                        <>
                          <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'top', marginTop: 2 }}>
                            <Grid item lg={4} md={4} xl={4} xs={12}>
                              <Backdrop className={classes.backdrop} open={open}>
                                <Grid
                                  container
                                  spacing={3}
                                  direction="column"
                                  justifyContent="center"
                                  alignItems="center"
                                  alignContent="center"
                                  wrap="nowrap"
                                >
                                  <Grid item>
                                    <CircularProgress color="primary" />
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="h4" color="textPrimary">
                                      Enviando foto...
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Backdrop>
                              <Autocomplete
                                value={valueTipoFoto}
                                onChange={(event, newValueTipoFoto) => {
                                  setValueTipoFoto(newValueTipoFoto);
                                }}
                                disabled={isEditable(ordemServico.ultimoEvento) == false}
                                inputValue={inputValueTipoFoto}
                                onInputChange={(event, newInputValueTipoFoto) => {
                                  setInputValueTipoFoto(newInputValueTipoFoto);
                                }}
                                id="controllable-states-demo"
                                options={optionsTiposFotos}
                                margin="normal"
                                variant="outlined"
                                defaultValue={valueTipoFoto}
                                getOptionLabel={(optionTipoFoto) => (optionTipoFoto ? optionTipoFoto.nome : '')}
                                renderInput={(params) => <TextField {...params} label="Tipo de Foto * " fullWidth />}
                                fullWidth
                              />
                            </Grid>
                            <Grid item lg={3} md={3} xl={3} xs={12}>
                              <ImageUploader
                                images={images}
                                onChangeImage={onChangeImage}
                                onError={errorsUploadImage}
                                acceptType={['jpeg', 'jpg', 'png', 'webp', 'jfif', 'bmp', 'tiff']}
                                maxFileSize={5000000}
                                resolutionWidth={5000}
                                showLegend={false}
                                resolutionHeight={5000}
                                resolutionType={"less"}
                                removeImage={removeImage}
                                props={ordemServico}
                                imagem={imagem}
                                getInitials={getInitials}
                                classes={classes}
                                rest={rest}
                                className={className}
                              />
                            </Grid>
                            <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                              <Button style={{ width: '200px', marginLeft: '15px', backgroundColor: '#000' }} name="btnUsuarios" id="btnUsuarios" variant="contained" className="btn" component='a' onClick={handleAddOnList}>Adicionar Foto</Button>
                            </Grid>
                          </Grid>
                          {props.ordemServico.ordensServicosFotos && props.ordemServico.ordensServicosFotos.length > 0 &&
                            <Grid container lg={12} md={12} xl={12} xs={12} spacing={2} align="center" style={{ justifyContent: 'center', marginTop: 35 }}>
                              <Typography><b>Fotos já carregadas</b></Typography>
                            </Grid>
                          }
                        </>
                      }
                      <Grid container lg={12} md={12} xl={12} xs={12} spacing={2} align="center" style={{ justifyContent: 'center', paddingTop: 12 }}>
                        {props.ordemServico.ordensServicosFotos && props.ordemServico.ordensServicosFotos.length > 0 ?
                          props.ordemServico.ordensServicosFotos.map(o => {
                            return (
                              <Grid item lg={3} md={3} xl={3} xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Grid container sx={{ display: 'flex', flexDirection: 'column' }} >
                                  {o.tipoFoto ?
                                    <Chip style={{ padding: 0 }} label={<Typography>
                                      {o.tipoFoto.nome}
                                    </Typography>}
                                    />
                                    :
                                    <Chip style={{ padding: 0 }} label={<Typography>
                                      {tipoFoto?.find((e) => e.value == o.idTipoFoto).text}
                                    </Typography>}
                                    />
                                  }
                                  <Avatar
                                    onClick={(e) => handleImage(o.caminhoFoto)}
                                    sx={{ width: '100%', height: 180, objectFit: 'cover', marginTop: 1 }}
                                    key={(imagem != '' && imagem != null ? imagem.substr(imagem.lastIndexOf('/'), imagem.length - imagem.lastIndexOf('/')) : '')}
                                    variant="rounded"
                                    src={o.caminhoFoto}
                                  />
                                  {isEditable(ordemServico.ultimoEvento) == true ?
                                    <Button variant="outlined" onClick={() => handleRemoveOnList(o.caminhoFoto)} size="small" sx={{ color: '#000', marginTop: 1, borderColor: '#000' }}>
                                      Remover foto&nbsp;<DeleteIcon />
                                    </Button>
                                    : null}
                                </Grid>
                                <Modal
                                  className={classes.modal}
                                  open={openImage}
                                  onClose={handleCloseImage}
                                  closeAfterTransition
                                  BackdropProps={{
                                    timeout: 500
                                  }}
                                >
                                  <Fade in={openImage}>
                                    <img
                                      src={image}
                                      alt="asd"
                                      style={{ maxHeight: "90%", maxWidth: "90%", borderRadius: 5 }}
                                    />
                                  </Fade>
                                </Modal>
                              </Grid>
                            )
                          }) :
                          isEditable(ordemServico.ultimoEvento) == false ?
                            <Typography variant="subtitle2" align="center" style={{ marginTop: 20 }}>
                              Nenhuma foto carregada
                            </Typography>
                            : null
                        }
                      </Grid>
                    </ValidatorForm>
                    <CustomCardActions>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                          * Campos obrigatórios
                        </Typography>
                      </Grid>
                      <CustomGridButtonsAction item lg={12} md={12} xl={12} xs={12}>
                        {(props.ordemServico.ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS && !authActions.isEmpresa()) ?
                          <AccessControl
                            rule={'ordensServico.alterStatus'} //somente o admin
                            yes={() => (
                              <CustomButton
                                variant="outlined"
                                onClick={() => handleDevolveOrdemServicoRevisao(params.id)}
                                startIcon={<EditNoteIcon />}
                                endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                              >
                                {props.ordemServico.isLoading ? '...' : 'Devolver ordem de serviço para ajustes'}
                              </CustomButton>
                            )
                            }
                          />
                          :
                          null
                        }
                        {
                          //define que a empresa não deseja se candidatar para aquela O.S, e que a empresa ainda não
                          //RECUSOU o envio de orçamentos
                          (props.ordemServico.ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS
                            && authActions.isEmpresa()
                            && !isRefuseByEmpresa(props.ordemServico, idEmpresa)) ?
                            <CustomButton
                              variant="outlined"
                              onClick={() => handleDeclinarOrcamento(params.id)}
                              startIcon={<CloseIcon />}
                              endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                            >
                              {props.ordemServico.isLoading ? '...' : 'Não conseguirei atender esta ordem de serviço'}
                            </CustomButton>
                            :
                            null
                        }
                        <CustomButton
                          variant="outlined"
                          onClick={() => history.push('/ordensServico')}
                        >
                          Voltar
                        </CustomButton>
                        {ordemServico.id && ordemServico.ultimoEvento == STATUS_SERVICO.PENDENTE && authActions.isGestor() ?
                          <>
                            <AccessControl
                              rule={'ordensServico.refusal'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <CustomButton
                                  variant="outlined"
                                  onClick={handleRecusaOrdemServico}
                                  disabled={props.ordemServico.isLoading}
                                  startIcon={<CloseIcon />}
                                  endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                                >
                                  {props.ordemServico.isLoading ? 'Recusando ordem de serviço...' : 'Recusar'}
                                </CustomButton>
                              )
                              }
                            />
                            <AccessControl
                              rule={'ordensServico.approve'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <CustomButton
                                  variant="outlined"
                                  onClick={handleAprovaOrdemServico}
                                  disabled={props.ordemServico.isLoading}
                                  startIcon={<DoneIcon />}
                                  endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                                >
                                  {props.ordemServico.isLoading ? 'Aprovando ordem de serviço...' : 'Aprovar'}
                                </CustomButton>
                              )
                              }
                            />
                          </>
                          : null}
                        {authActions.isEmpresa() && ordemServico.ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO && isWinner(ordemServico, idEmpresa) ?
                          <CustomButton
                            variant="contained"
                            dark={true}
                            onClick={handleFinalizaOrdemServico}
                            disabled={props.ordemServico.isLoading || ordemServico.ultimoEvento != STATUS_SERVICO.ORCAMENTO_APROVADO}
                            startIcon={<FactCheckOutlinedIcon />}
                            endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                          >
                            {props.ordemServico.isLoading ? 'Concluindo ordem de serviço...' : 'Concluir Serviço'}
                          </CustomButton>
                          :
                          authActions.isFiscal() && ordemServico.ultimoEvento == STATUS_SERVICO.CONCLUIDO ?
                            <CustomButton
                              variant="contained"
                              dark={true}
                              onClick={handleVerificaOrdemServico}
                              disabled={props.ordemServico.isLoading || ordemServico.ultimoEvento != STATUS_SERVICO.CONCLUIDO}
                              startIcon={<CheckIcon />}
                              endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                            >
                              {props.ordemServico.isLoading ? 'Verificando ordem de serviço...' : 'Verificar Ordem de Serviço'}
                            </CustomButton>
                            :
                            ordemServico.ultimoEvento == STATUS_SERVICO.LANCAMENTO ?
                              <AccessControl
                                rule={'ordensServico.validate'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <CustomButton
                                    variant="contained"
                                    dark={true}
                                    onClick={handleValidaOrdemServico}
                                    disabled={props.ordemServico.isLoading || ordemServico.ultimoEvento != STATUS_SERVICO.LANCAMENTO}
                                    startIcon={<FactCheckOutlinedIcon />}
                                    endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                                  >
                                    {props.ordemServico.isLoading ? 'Validando ordem de serviço...' : 'Validar Ordem de Serviço'}
                                  </CustomButton>
                                )
                                }
                              />
                              :
                              <CustomButton
                                type="submit"
                                variant="contained"
                                dark={true}
                                disabled={props.ordemServico.isLoading || ordemServico.ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS || ordemServico.ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO}
                                startIcon={<SaveIcon />}
                                endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                              >
                                {(props.ordemServico.id || params.id) ? (props.ordemServico.isLoading ? 'Atualizando ordem de serviço...' : 'Atualizar') : (props.ordemServico.isLoading ? 'Salvando ordem de serviço...' : 'Salvar')}
                              </CustomButton>
                        }
                      </CustomGridButtonsAction>
                    </CustomCardActions>
                  </CardContent>
                  <CardHeader title={<Typography variant="h6" align="center">
                    <b>{'Etapas da Ordem de Serviço'}</b>
                  </Typography>}>
                  </CardHeader>
                  <CardContent>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      {ordemServico && ordemServico.eventos && ordemServico.eventos.length > 0 ? (
                        <CustomStepper
                          orientation={isHorizontal ? 'horizontal' : 'vertical'}
                          alternativeLabel={false}
                        // activeStep={
                        //   ordemServico.eventos && ordemServico.eventos.length > 0
                        //     ? (() => {
                        //       if (ordemServico.eventos.some(e => e.tipo == null) && ordemServico.mecanicoDelivery) {
                        //         return parseInt(ordemServico.ultimoEvento) - 2;
                        //       } else if (parseInt(ordemServico.ultimoEvento) == STATUS_SERVICO.ORCAMENTO_APROVADO && (ordemServico.mecanicoDelivery || !ordemServico.eventos.some(e => e.tipo == null))) {
                        //         return 1;
                        //       } else if (parseInt(ordemServico.ultimoEvento) == STATUS_SERVICO.AVALIADO && !ordemServico.eventos.some(e => e.tipo == null)) {
                        //         return 5;
                        //       } else if (parseInt(ordemServico.ultimoEvento) == STATUS_SERVICO.AVALIADO && ordemServico.eventos.some(e => e.tipo == null)) {
                        //         return 6;
                        //       } else if (parseInt(ordemServico.ultimoEvento) == STATUS_SERVICO.VERIFICADO && !ordemServico.eventos.some(e => e.tipo == null)) {
                        //         return parseInt(ordemServico.ultimoEvento) - 3;
                        //       } else if (parseInt(ordemServico.ultimoEvento) == STATUS_SERVICO.VERIFICADO && ordemServico.eventos.some(e => e.tipo == null)) {
                        //         return parseInt(ordemServico.ultimoEvento) - 2;
                        //       } else if (ordemServico.ultimoEvento == STATUS_SERVICO.LANCAMENTO) {
                        //         return 0;
                        //       } else if (ordemServico.eventos.some(e => e.tipo == null) && parseInt(ordemServico.ultimoEvento) == STATUS_SERVICO.PENDENTE) {
                        //         return 1;
                        //       } else if (parseInt(ordemServico.ultimoEvento) == STATUS_SERVICO.CONCLUIDO && ordemServico.eventos.some(e => e.tipo == null)) {
                        //         return parseInt(ordemServico.ultimoEvento) - 1;
                        //       } else if (parseInt(ordemServico.ultimoEvento) == STATUS_SERVICO.CONCLUIDO && !ordemServico.eventos.some(e => e.tipo == null)) {
                        //         return parseInt(ordemServico.ultimoEvento) - 1;
                        //       } else {
                        //         return parseInt(ordemServico.ultimoEvento) - 1;
                        //       }
                        //     })()
                        //     : -1
                        // }
                        >
                          {ordemServico.eventos
                            // Ordena os eventos pela data
                            .sort((a, b) => new Date(a.data) - new Date(b.data))
                            .map((evento) => {
                              // Obtém o step correspondente ao evento
                              const step = steps.find(s => s.tipo === evento.tipo);

                              return (
                                <Step active key={evento.id}>
                                  <StepLabel StepIconComponent={(props) => (
                                    <ColorlibStepIcon {...props} evento={evento.tipo} />
                                  )}>
                                    <div>
                                      <b>{getNomeEvento(evento.tipo)}</b> {/* Exibe o nome do step associado ao evento */}
                                      <br></br>
                                      {getNomeEvento(evento.tipo) != "EM LANÇAMENTO" && getNomeEvento(evento.tipo) != "PENDENTE" && getNomeEvento(evento.tipo) != "RECEBENDO ORÇAMENTOS" && getNomeEvento(evento.tipo) != "AVALIADA" && ordemServico.empresa && (
                                        <>
                                        </>
                                      )}
                                      {getNomeEvento(evento.tipo) == "AVALIADA" && (
                                        <>
                                          {ordemServico.avaliacao != 0 && <Stars nota={ordemServico.avaliacao} avaliacoes={1} timeline={true} />}
                                          <br />
                                        </>
                                      )}
                                      {moment(evento?.data).format("DD/MM/YYYY HH:mm")}
                                    </div>
                                  </StepLabel>
                                </Step>
                              );
                            })}

                          {ordemServico.ultimoEvento < STATUS_SERVICO.RECUSADO &&
                            <Step>
                              <StepLabel StepIconComponent={(props) => (
                                <ColorlibStepIcon {...props} evento={STATUS_SERVICO.CONCLUIDO} />
                              )}>
                                <b>{getNomeEvento(STATUS_SERVICO.CONCLUIDO)}</b>
                              </StepLabel>
                            </Step>
                          }
                          {ordemServico.ultimoEvento < STATUS_SERVICO.AVALIADO &&
                            <Step>
                              <StepLabel StepIconComponent={(props) => (
                                <ColorlibStepIcon {...props} evento={STATUS_SERVICO.AVALIADO} />
                              )}>
                                <b>{getNomeEvento(STATUS_SERVICO.AVALIADO)}</b>
                              </StepLabel>
                            </Step>
                          }
                          {ordemServico.ultimoEvento < STATUS_SERVICO.VERIFICADO &&
                            <Step>
                              <StepLabel StepIconComponent={(props) => (
                                <ColorlibStepIcon {...props} evento={STATUS_SERVICO.VERIFICADO} />
                              )}>
                                <b>{getNomeEvento(STATUS_SERVICO.VERIFICADO)}</b>
                              </StepLabel>
                            </Step>
                          }

                        </CustomStepper>
                      ) : (
                        <CustomStepper
                          orientation={'vertical'}
                          alternativeLabel={false}
                          activeStep={-1}
                          connector={<ColorlibConnector />}
                        >
                          {steps?.map((step) => (
                            <Step key={step.tipo}>
                              <StepLabel StepIconComponent={(props) => (
                                <ColorlibStepIcon {...props} evento={step.tipo} />
                              )}>
                                <b>{step.nome}</b>
                              </StepLabel>
                            </Step>
                          ))}
                        </CustomStepper>
                      )}

                    </Grid>
                    {authActions.isGestor() && ordemServico.eventos && ordemServico.eventos.length > 0 &&
                      <Grid item lg={12} md={12} xl={12} xs={12} align="center" style={{ marginTop: 8 }}>
                        <CustomButton
                          onClick={handleOpenModalEventos}
                          variant="outlined"
                          disabled={props.ordemServico.isLoading}
                          endIcon={props.ordemServico.isLoading && <AccessTimeIcon size={24} />}
                        >
                          Visualizar eventos
                        </CustomButton>
                      </Grid>
                    }
                  </CardContent>
                </ValidatorForm>
              </TabPanel>
              {(authActions.isGestor() || authActions.isPrefeitura()) &&
                <>
                  <TabPanel value={tabValue} index={1}>
                    {ordemServico.id && ordemServico.ordensServicosOcorrencias && ordemServico.ordensServicosOcorrencias.length > 0 ?
                      <OrdemServicoOcorrenciaChat props={props} />
                      :
                      <Typography variant="subtitle2" align="center">
                        Não há ocorrências registradas para esta Ordem de Serviço
                      </Typography>
                    }
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    {
                      ordemServico.id
                        && ordemServico.ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS
                        && (authActions.isPrefeitura() || authActions.isGestor()) ?
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={7}>
                                <Typography variant="h6" align="center">
                                  <b>{'Orçamentos para aprovação'}</b>
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={1}>
                                <Typography variant="h6" align="center">
                                  <IconButton
                                    aria-label="Ajuda"
                                    component="a"
                                    onClick={() =>
                                      handleOpenModalHelp()
                                    }
                                    size="small"
                                  >
                                    <Tooltip title={'Ajuda'}>
                                      <Tooltip>
                                        <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Data</TableCell>
                              <TableCell>Prazo</TableCell>
                              <TableCell>Valor</TableCell>
                              <TableCell>Empresa</TableCell>
                              <TableCell>Possui garantia</TableCell>
                              <TableCell>Arquivo(s)</TableCell>
                              <TableCell>Observações</TableCell>
                              <TableCell align="right">Ações</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orcamentosValidos && orcamentosValidos.length > 0 ? (
                              orcamentosValidos.map((n) => (
                                <TableRow hover key={n.id}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ backgroundColor: isMenorPreco(n.valor) ? '#8DE3C4' : '' }}
                                  >
                                    {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ backgroundColor: isMenorPreco(n.valor) ? '#8DE3C4' : '' }}
                                  >
                                    {n.prazo ? moment(n.prazo).format("DD/MM/YYYY") : ''}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ backgroundColor: isMenorPreco(n.valor) ? '#8DE3C4' : '' }}
                                  >
                                    {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ backgroundColor: isMenorPreco(n.valor) ? '#8DE3C4' : '' }}
                                  >
                                    {n.empresa ? n.empresa.razaoSocial.toUpperCase() : ''}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ backgroundColor: isMenorPreco(n.valor) ? '#8DE3C4' : '' }}
                                  >
                                    {n.possuiGarantia ? 'SIM' : 'NÃO'}
                                  </TableCell>
                                  <Tooltip
                                    title={n.ordensServicosOrcamentosAnexos?.length > 0 ? "Clique aqui para abrir o arquivo em uma nova aba" : ''}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        cursor: n.ordensServicosOrcamentosAnexos?.length > 0 ? 'pointer' : 'default',
                                        backgroundColor: isMenorPreco(n.valor) ? '#8DE3C4' : '',
                                        marginTop: 'auto'
                                      }}
                                    >
                                      {n.ordensServicosOrcamentosAnexos && Array.isArray(n.ordensServicosOrcamentosAnexos) && n.ordensServicosOrcamentosAnexos.length > 0 ? (
                                        n.ordensServicosOrcamentosAnexos.map((anexo, index) => (
                                          <InsertDriveFileOutlinedIcon
                                            key={anexo.id || index}
                                            onClick={() => window.open(anexo.caminhoArquivo, '_blank')}
                                            style={{
                                              cursor: 'pointer',
                                              fontSize: '1.5em',
                                              verticalAlign: 'middle',
                                              marginRight: '8px'
                                            }}
                                          />
                                        ))
                                      ) : (
                                        '-'
                                      )}
                                    </TableCell>
                                  </Tooltip>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ backgroundColor: isMenorPreco(n.valor) ? '#8DE3C4' : '' }}
                                  >
                                    {n.observacoes ? n.observacoes.toUpperCase() : '-'}
                                  </TableCell>
                                  <TableCell align="right" style={{ backgroundColor: isMenorPreco(n.valor) ? '#8DE3C4' : '' }}>
                                    <AccessControl
                                      rule={'ordensServicoOrcamento.approve'}
                                      yes={() => (
                                        <IconButton
                                          aria-label="Aprovar"
                                          onClick={() => handleAprovaOrcamento(n)}
                                          size="small"
                                        >
                                          <Tooltip title="Aprovar orçamento">
                                            <DoneIcon />
                                          </Tooltip>
                                        </IconButton>
                                      )}
                                      no={() => <AccessDenied />}
                                    />
                                    <AccessControl
                                      rule={'ordensServicoOrcamento.refusal'}
                                      yes={() => (
                                        <IconButton
                                          aria-label="Recusar"
                                          onClick={() => handleRecusaOrcamento(n.id)}
                                          size="small"
                                        >
                                          <Tooltip title="Recusar orçamento">
                                            <CloseIcon />
                                          </Tooltip>
                                        </IconButton>
                                      )}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow hover>
                                <TableCell component="th" scope="row" align="center" colSpan={8}>
                                  Nenhum orçamento passível de aprovação foi recebido
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                        : ordemServico.id && (ordemServico.ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO || ordemServico.ultimoEvento == STATUS_SERVICO.CONCLUIDO || ordemServico.ultimoEvento == STATUS_SERVICO.AVALIADO) && (authActions.isPrefeitura() || authActions.isGestor() || authActions.isEmpresa()) &&
                          (ordemServico.mecanicoDelivery == false || ordemServico.mecanicoDelivery == null) ?
                          <>
                            <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5, alignContent: 'center', marginTop: 3 }}>
                              <CardHeader
                                subheader=""
                                title={<SegHeaderOrcamentoAprovado />}
                              />
                              <Grid item lg={1} md={1} xl={1} xs={12}>
                                <IconButton
                                  aria-label="Ajuda"
                                  component="a"
                                  onClick={() =>
                                    handleOpenModalHelp()
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Ajuda'}>
                                    <Tooltip>
                                      <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                              {(authActions.isPrefeitura() || authActions.isGestor()) &&
                                <Grid item>
                                  <Button
                                    style={{ borderColor: '#000', color: '#000' }}
                                    type="button"
                                    size="small"
                                    onClick={() => handleExportExcel()}
                                    variant="outlined"
                                    endIcon={<FontAwesomeIcon icon={faFileExcel} style={{ verticalAlign: 'middle' }} />}
                                  >
                                    Exportar para Excel
                                  </Button>
                                  <Button
                                    style={{ borderColor: '#000', color: '#000' }}
                                    type="button"
                                    size="small"
                                    onClick={() => handleExportPDF()}
                                    variant="outlined"
                                    endIcon={<FontAwesomeIcon icon={faFilePdf} style={{ verticalAlign: 'middle' }} />}
                                  >
                                    Exportar para PDF
                                  </Button>
                                </Grid>
                              }
                            </Grid>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Data</TableCell>
                                  <TableCell>Prazo</TableCell>
                                  <TableCell>Valor</TableCell>
                                  <TableCell>Empresa</TableCell>
                                  <TableCell>Possui garantia</TableCell>
                                  <TableCell>Arquivo(s)</TableCell>
                                  <TableCell>Motivo</TableCell>
                                  <TableCell>Observações</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {undefined !== ordemServico.ordensServicosOrcamentos && ordemServico.ordensServicosOrcamentos.length
                                  ? ordemServico.ordensServicosOrcamentos.map((n) => {
                                    return (
                                      n.statusOrcamento === 1 &&
                                      <TableRow hover key={n.id}>
                                        <TableCell component="th" scope="row">
                                          {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {n.prazo ? moment(n.prazo).format("DD/MM/YYYY") : ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {n.empresa ? n.empresa.razaoSocial.toUpperCase() : ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {n.possuiGarantia ? 'SIM' : 'NÃO'}
                                        </TableCell>
                                        <Tooltip title={n.ordensServicosOrcamentosAnexos?.length > 0 && "Clique aqui para abrir o arquivo em uma nova aba"}>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                              cursor: n?.length > 0 ? 'pointer' : 'default',
                                              marginTop: 'auto'
                                            }}
                                          >
                                            {n.ordensServicosOrcamentosAnexos && Array.isArray(n.ordensServicosOrcamentosAnexos) && n.ordensServicosOrcamentosAnexos.length > 0 ? (
                                              n.ordensServicosOrcamentosAnexos.map((anexo, index) => (
                                                <InsertDriveFileOutlinedIcon
                                                  key={index}
                                                  onClick={() => window.open(anexo.caminhoArquivo, '_blank')}  // Abre o arquivo em uma nova aba
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontSize: '1.5em',
                                                    verticalAlign: 'middle',
                                                    marginRight: '8px' // Adiciona espaço entre os ícones
                                                  }}
                                                />
                                              ))
                                            ) : (
                                              '-'
                                            )}
                                          </TableCell>
                                        </Tooltip>
                                        <TableCell component="th" scope="row">
                                          {n.motivo ? n.motivo.toUpperCase() : '-'}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {n.observacoes ? n.observacoes.toUpperCase() : ''}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  }) : null}
                              </TableBody>
                            </Table>
                            <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', marginTop: 10 }}>
                              <CardHeader
                                subheader=""
                                title={<SegHeaderOrcamentoRecusado />}
                              />
                            </Grid>
                            <Table style={{ marginTop: 5 }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Data</TableCell>
                                  <TableCell>Prazo</TableCell>
                                  <TableCell>Valor</TableCell>
                                  <TableCell>Empresa</TableCell>
                                  <TableCell>Possui garantia</TableCell>
                                  <TableCell>Observações</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {orcamentosRecusados && orcamentosRecusados.length > 0 ? (
                                  orcamentosRecusados.map((n) => (
                                    <TableRow hover key={n.id}>
                                      <TableCell component="th" scope="row">
                                        {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {n.prazo ? moment(n.prazo).format("DD/MM/YYYY") : ''}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {n.empresa ? n.empresa.razaoSocial.toUpperCase() : ''}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {n.possuiGarantia ? 'SIM' : 'NÃO'}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {n.observacoes ? n.observacoes.toUpperCase() : ''}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={6} align="center">Nenhum orçamento recusado para exibir.</TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>

                          </>
                          : ordemServico.id && (ordemServico.ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO || ordemServico.ultimoEvento == STATUS_SERVICO.CONCLUIDO || ordemServico.ultimoEvento == STATUS_SERVICO.AVALIADO) && (authActions.isPrefeitura() || authActions.isGestor() || authActions.isEmpresa()) &&
                            (ordemServico.mecanicoDelivery == true) ?
                            <Typography variant="body1" align="center" style={{ marginTop: 15 }}>
                              Esta ordem de serviço foi encaminhada para o mecânico delivery, e não recebeu orçamentos
                            </Typography>
                            : ordemServico.id && ordemServico.ultimoEvento == STATUS_SERVICO.PENDENTE
                              && (authActions.isPrefeitura() || authActions.isGestor()) ?
                              <Typography variant="subtitle2" align="center">
                                Esta ordem de serviço ainda não está recebendo orçamentos
                              </Typography>
                              : ordemServico.ultimoEvento == STATUS_SERVICO.RECUSADO ?
                                <Typography variant="subtitle2" align="center">
                                  Esta ordem de serviço foi recusada e não receberá orçamentos
                                </Typography>
                                :
                                <Typography variant="subtitle2" align="center">
                                  Esta ordem de serviço ainda não está recebendo orçamentos
                                </Typography>
                    }
                    {ordemServico.id && (authActions.isPrefeitura() || authActions.isGestor()) && ordemServico.ultimoEvento != STATUS_SERVICO.PENDENTE ?
                      <>
                        <Grid container spacing={2} style={{ marginTop: 10, justifyContent: ordemServico.ultimoEvento === STATUS_SERVICO.RECEBENDO_ORCAMENTOS ? 'center' : 'left' }}>
                          <CardHeader
                            subheader=""
                            title={<SegHeaderOrcamentDeclinado />}
                          />
                        </Grid>
                        <Table style={{ marginTop: 5 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Data</TableCell>
                              <TableCell>Empresa</TableCell>
                              <TableCell>Justificativa</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orcamentosDeclinados && orcamentosDeclinados.length > 0 ? (
                              orcamentosDeclinados.map((n) => (
                                <TableRow hover key={n.id}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                  >
                                    {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                  >
                                    {n.empresa ? n.empresa.razaoSocial.toUpperCase() : ''}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                  >
                                    {n.observacoes ? n.observacoes.toUpperCase() : ''}
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow hover>
                                <TableCell component="th" scope="row" align="center" colSpan={3}>
                                  Nenhum declínio de orçamento foi recebido
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </>
                      :
                      null
                    }
                    {props.ordemServico.ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO ?
                      <AccessControl
                        rule={'ordensServico.alterStatus'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <Grid item lg={12} md={12} xl={12} xs={12} style={{
                            display: 'flex',
                            alignItems: 'right',
                            flexDirection: 'row',
                            textAlign: 'right',
                            justifyContent: 'right'
                          }}>
                            <CustomButton
                              dark={true}
                              startIcon={<UndoIcon />}
                              style={{ color: '#fff', marginTop: '10px' }}
                              onClick={() => handleReverterAprovacaoOrcamento()}
                            >
                              Reverter aprovação de orçamento
                            </CustomButton>
                          </Grid>
                        )
                        }
                      />
                      : null
                    }
                  </TabPanel>
                  <TabPanel value={tabValue} index={3}>
                    <OrdemServicoFinanceiroEdit props={props} idOrdemServico={params.id} ordemServico={ordemServico} />
                  </TabPanel>
                </>
              }
              {(authActions.isPrefeitura() || authActions.isMotorista()) &&
                <TabPanel value={tabValue} index={4}>
                  {ordemServico.id &&
                    (ordemServico.ultimoEvento == STATUS_SERVICO.CONCLUIDO || ordemServico.ultimoEvento == STATUS_SERVICO.AVALIADO) ? //concluída ou avaliada
                    <Card>
                      <CardHeader
                        title={
                          <Typography variant="h6" align="center">
                            <b>{'Avaliação'}</b>
                          </Typography>}>
                      </CardHeader>
                      <CardContent sx={{ marginTop: 0, paddingTop: 0 }}>
                        <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                          <Grid item lg={7} md={7} xl={7} xs={12}>
                            <Input
                              className={classes.textField}
                              fullWidth
                              id="nome"
                              onChange={handleChange('descritivoAvaliacao')}
                              disabled={ordemServico.id && ordemServico.ultimoEvento == STATUS_SERVICO.AVALIADO}
                              label="Nome *"
                              margin="normal"
                              value={props.ordemServico.descritivoAvaliacao}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item lg={4} md={4} xl={4} xs={12}>
                            {ordemServico.id &&
                              ordemServico.ultimoEvento == STATUS_SERVICO.AVALIADO ?
                              <Typography component="legend">Avaliada com nota <b>{ordemServico.avaliacao}</b></Typography>
                              :
                              <Typography component="legend">Avalie, com uma nota de 1 a 5</Typography>}
                            <Rating
                              name="simple-controlled"
                              value={rate ? rate : props.ordemServico.avaliacao}
                              onChange={(event, newRate) => {
                                setRate(newRate);
                              }}
                              disabled={ordemServico.id && ordemServico.ultimoEvento == STATUS_SERVICO.AVALIADO}
                              onChangeActive={(event, newHover) => {
                                setHover(newHover);
                              }}
                            />
                            {rate !== null && (
                              <Box sx={{ ml: 1 }}>{labels[hover !== -1 ? hover : rate]}</Box>
                            )}
                          </Grid>
                          <Grid item lg={12} md={12} xl={12} xs={12} style={{
                            display: 'flex',
                            alignItems: 'right',
                            flexDirection: 'row',
                            textAlgin: 'right',
                            justifyContent: 'right'
                          }}>
                            <Button
                              variant="outlined"
                              style={{ color: '#000', borderColor: '#000' }}
                              onClick={() => history.push('/ordensServico')}
                            >
                              Cancelar
                            </Button>
                            {(authActions.isMotorista() || authActions.isFiscal() || authActions.isPrefeitura()) ?
                              <Button
                                type="submit"
                                variant="contained"
                                style={{ backgroundColor: '#000', color: '#FFF' }}
                                disabled={props.ordemServico.isLoading || ordemServico.ultimoEvento != STATUS_SERVICO.CONCLUIDO}
                                startIcon={<SaveIcon />}
                                onClick={handleAvaliarOrdemServico}
                                endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                              >
                                {props.ordemServico.isLoading ? 'Avaliando ordem de serviço...' : 'Avaliar'}
                              </Button>
                              : null}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    :
                    ordemServico.id &&
                      ordemServico.ultimoEvento == STATUS_SERVICO.RECUSADO ?
                      <Typography variant="subtitle2" align="center">
                        Esta Ordem de Serviço foi recusada e não receberá uma avaliação
                      </Typography>
                      : ordemServico.ultimoEvento != STATUS_SERVICO.AVALIADO &&
                      <Typography variant="subtitle2" align="center">
                        Esta Ordem de Serviço ainda não possui uma avaliação
                      </Typography>
                  }

                </TabPanel>
              }
              {authActions.isEmpresa() && (
                <>
                  <TabPanel value={tabValue} index={1}>
                    <OrdemServicoFinanceiroEdit props={props} idOrdemServico={params.id} ordemServico={props.ordemServico.ordemServico} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    {
                      ordemServico.id && (ordemServico.ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO || ordemServico.ultimoEvento == STATUS_SERVICO.CONCLUIDO || ordemServico.ultimoEvento == STATUS_SERVICO.AVALIADO) &&
                        (ordemServico.mecanicoDelivery == false || ordemServico.mecanicoDelivery == null) ?
                        <>
                          <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5, alignContent: 'center', marginTop: 3 }}>
                            <CardHeader
                              subheader=""
                              title={<SegHeaderOrcamentoAprovado />}
                            />
                          </Grid>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Data</TableCell>
                                <TableCell>Prazo</TableCell>
                                <TableCell>Valor</TableCell>
                                <TableCell>Empresa</TableCell>
                                <TableCell>Possui garantia</TableCell>
                                <TableCell>Arquivo</TableCell>
                                <TableCell>Motivo</TableCell>
                                <TableCell>Observações</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {undefined !== ordemServico.ordensServicosOrcamentos && ordemServico.ordensServicosOrcamentos.length
                                ? ordemServico.ordensServicosOrcamentos.map((n) => {
                                  return (
                                    n.statusOrcamento === 1 &&
                                    <TableRow hover key={n.id}>
                                      <TableCell component="th" scope="row">
                                        {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {n.prazo ? moment(n.prazo).format("DD/MM/YYYY") : ''}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {n.empresa ? n.empresa.razaoSocial.toUpperCase() : ''}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {n.possuiGarantia ? 'SIM' : 'NÃO'}
                                      </TableCell>
                                      <Tooltip title={n.caminhoAnexo && "Clique aqui para abrir o arquivo em uma nova aba"}>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            cursor: n.caminhoAnexo ? 'pointer' : 'default',
                                            marginTop: 'auto'
                                          }}
                                          onClick={() => handleOpenArquivo(n.caminhoAnexo)}
                                        >
                                          {n.caminhoAnexo ? (
                                            <InsertDriveFileOutlinedIcon
                                              style={{
                                                cursor: 'pointer',
                                                fontSize: '1.5em',
                                                verticalAlign: 'middle'
                                              }}
                                            />
                                          ) : (
                                            '-'
                                          )}
                                        </TableCell>
                                      </Tooltip>
                                      <TableCell component="th" scope="row">
                                        {n.motivo ? n.motivo.toUpperCase() : '-'}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {n.observacoes ? n.observacoes.toUpperCase() : ''}
                                      </TableCell>
                                    </TableRow>
                                  )
                                }) : null}
                            </TableBody>
                          </Table>
                          <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', marginTop: 10 }}>
                            <CardHeader
                              subheader=""
                              title={<SegHeaderOrcamentoRecusado />}
                            />
                          </Grid>
                          <Table style={{ marginTop: 5 }}>
                            <TableHead>
                              <TableRow>
                                <TableCell>Data</TableCell>
                                <TableCell>Prazo</TableCell>
                                <TableCell>Valor</TableCell>
                                <TableCell>Empresa</TableCell>
                                <TableCell>Possui garantia</TableCell>
                                <TableCell>Observações</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {orcamentosRecusados && orcamentosRecusados.length > 0 ? (
                                orcamentosRecusados.map((n) => (
                                  <TableRow hover key={n.id}>
                                    <TableCell component="th" scope="row">
                                      {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {n.prazo ? moment(n.prazo).format("DD/MM/YYYY") : ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {n.empresa ? n.empresa.razaoSocial.toUpperCase() : ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {n.possuiGarantia ? 'SIM' : 'NÃO'}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {n.observacoes ? n.observacoes.toUpperCase() : ''}
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={6} align="center">
                                    Nenhum orçamento recusado para exibir.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </>
                        : ordemServico.id && (ordemServico.ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO || ordemServico.ultimoEvento == STATUS_SERVICO.CONCLUIDO || ordemServico.ultimoEvento == STATUS_SERVICO.AVALIADO) &&
                          (ordemServico.mecanicoDelivery == true) ?
                          <Typography variant="body1" align="center" style={{ marginTop: 15 }}>
                            Esta ordem de serviço foi encaminhada para o mecânico delivery, e não recebeu orçamentos
                          </Typography>
                          : ordemServico.ultimoEvento == STATUS_SERVICO.RECUSADO ?
                            <Typography variant="subtitle2" align="center">
                              Esta ordem de serviço foi recusada e não receberá orçamentos
                            </Typography>
                            :
                            <Typography variant="subtitle2" align="center">
                              Nenhum orçamento para exibir
                            </Typography>
                    }
                    {ordemServico.ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO && isWinner(ordemServico, idEmpresa) ?
                      <AccessControl
                        rule={'ordensServico.alterStatus'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <Grid item lg={12} md={12} xl={12} xs={12} style={{
                            display: 'flex',
                            alignItems: 'right',
                            flexDirection: 'row',
                            textAlign: 'right',
                            justifyContent: 'right'
                          }}>
                            <CustomButton
                              dark={true}
                              startIcon={<UndoIcon />}
                              style={{ color: '#fff', marginTop: '10px' }}
                              onClick={() => handleReverterAprovacaoOrcamento()}
                            >
                              Não conseguirei realizar o serviço
                            </CustomButton>
                          </Grid>
                        )
                        }
                      />
                      : null
                    }
                  </TabPanel>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </Grid >
      {
        (modal ?
          <RedeCredenciadaOuMecanicoDeliveryModal
            toggle={toggleModal}
            modal={modal}
            props={props}
            veiculo={veiculo?.find(m => m.value == valueVeiculo?.id)}
            tipoServico={tipoServico?.find(m => m.value == props.ordemServico.idTipoServico)}
            orgao={orgao?.find(m => m.value == idOrgao != 0 ? idOrgao : props.ordemServico.idOrgao)}
          />
          :
          null
        )
      }

      {
        (modalConcluirOS ?
          <OrdemServicoModalConcluirServico
            toggle={toggleModalConcluirOs}
            modal={modalConcluirOS}
            props={props}
            idOrdemServico={params.id}
          />
          :
          null
        )
      }
      {
        modalHelp && (
          <HelpModal
            key={modalHelp ? 'modal-open' : 'modal-closed'}
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={(tabValue == 0 ? (!params.id || params.id == 0 || params.id == '')
              ? MANUAIS_CATEGORIAS.ABA_CADASTRO_ORDEM_SERVICO
              : MANUAIS_CATEGORIAS.ABA_EDICAO_ORDEM_SERVICO
              : tabValue == 2 ? MANUAIS_CATEGORIAS.ABA_ORDEM_SERVICO_ORCAMENTO : tabValue == 3 ? MANUAIS_CATEGORIAS.ABA_ORDEM_SERVICO_FINANCEIRO : 0)}
          />
        )
      }

      {
        (modalEventos ?
          <OrdemServicoModalEventos
            toggle={toggleModalEventos}
            modal={modalEventos}
            props={props}
            eventos={ordemServico.eventos}
            ordemServico={ordemServico}
          />
          :
          null
        )
      }

      {
        (modalOrcamentoMotivo ?
          <OrdemServicoMotivoModal
            toggle={toggleModalOrcamentoMotivo}
            modal={modalOrcamentoMotivo}
            props={props}
            idOrdemServico={params.id}
            orcamento={orcamento}
          />
          :
          null
        )
      }

      {
        (modalMotivoReverterStatus ?
          <OrdemServicoReverterStatusMotivoModal
            toggle={toggleModalMotivoReverterStatus}
            modal={modalMotivoReverterStatus}
            props={props}
            ordemServico={ordemServico}
          />
          :
          null
        )
      }

      {
        (modalMotivoDeclinar ?
          <OrdemServicoDeclinarOrcamentoMotivoModal
            toggle={toggleModalMotivoDeclinar}
            modal={modalMotivoDeclinar}
            props={props}
            ordemServico={ordemServico}
          />
          :
          null
        )
      }
    </Page >
  )
}

OrdemServicoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return state
}

const connectedOrdemServicoEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(OrdemServicoEdit))
)
export { connectedOrdemServicoEditPage as OrdemServicoEdit }
