import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'
import moment from 'moment'

export const relatorioAction = {
  getRelatorioEmpresaPorOrgao,
  getRelatorioEmpresaOrgaoArquivo,
  getRelatorioLogsSistema,
  getRelatorioLogsSistemaArquivo,
  getRelatorioServicosRealizados,
  getRelatorioServicosRealizadosArquivo,
  getRelatorioOrcamentos,
  getRelatorioOrcamentosArquivo,
  getRelatorioOcorrenciaOrdemServico,
  getRelatorioOcorrenciaOrdemServicoArquivo,
  getRelatorioOcorrenciaTelemetriaVeiculo,
  getRelatorioOcorrenciaTelemetriaVeiculoArquivo,
  clear,
  clearAll,
}
function getRelatorioOcorrenciaOrdemServico(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/ocorrenciasOrdemServico' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(changeRelatorioList(response.data, null))
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getRelatorioOcorrenciaOrdemServicoArquivo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/ocorrenciasOrdemServico/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          const BOM = '\uFEFF';
          var FileSaver = require('file-saver');
          var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
          var data = moment(new Date()).format("DD/MM/YYYY")
          FileSaver.saveAs(blob, 'relatorio_ocorrencias_ordemServico' + data + '.csv');
          toast.success('Download do arquivo realizado com sucesso!')
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getRelatorioOcorrenciaTelemetriaVeiculo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/ocorrenciasTelemetriaVeiculo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(changeRelatorioList(response.data.items, response.data.totalRows))
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getRelatorioOcorrenciaTelemetriaVeiculoArquivo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/ocorrenciasTelemetriaVeiculo/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          const BOM = '\uFEFF';
          var FileSaver = require('file-saver');
          var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
          var data = moment(new Date()).format("DD/MM/YYYY")
          FileSaver.saveAs(blob, 'relatorio_ocorrencias_telemetria_veiculo' + data + '.csv');
          toast.success('Download do arquivo realizado com sucesso!')
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getRelatorioLogsSistema(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/logsSistema' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(changeRelatorioList(response.data.items, response.data.totalRows))
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getRelatorioLogsSistemaArquivo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/logsSistema/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          const BOM = '\uFEFF';
          var FileSaver = require('file-saver');
          var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
          var data = moment(new Date()).format("DD/MM/YYYY")
          FileSaver.saveAs(blob, 'relatorio_logs_sistema' + data + '.csv');
          toast.success('Download do arquivo realizado com sucesso!')
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getRelatorioServicosRealizados(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/servicosRealizados' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(changeRelatorioList(response.data.items, response.data.totalRows))
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getRelatorioServicosRealizadosArquivo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/servicosRealizados/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          const BOM = '\uFEFF';
          var FileSaver = require('file-saver');
          var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
          var data = moment(new Date()).format("DD/MM/YYYY")
          FileSaver.saveAs(blob, 'relatorio_servicos_realizados_' + data + '.csv');
          toast.success('Download do arquivo realizado com sucesso!')
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getRelatorioOrcamentos(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/orcamentos/' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(changeRelatorioList(response.data, null))
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(notIsLoading())
        return err.response;
      })
  }
}
function getRelatorioOrcamentosArquivo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/orcamentos/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          const BOM = '\uFEFF';
          var FileSaver = require('file-saver');
          var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
          var data = moment(new Date()).format("DD/MM/YYYY")
          FileSaver.saveAs(blob, 'relatorio_orcamentos_' + data + '.csv');
          toast.success('Download do arquivo realizado com sucesso!')
          dispatch(notIsLoading())
          dispatch(clear())
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getRelatorioEmpresaPorOrgao(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/empresasPorOrgao/' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(changeRelatorioList(response.data, null))
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getRelatorioEmpresaOrgaoArquivo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'relatorios/empresasOrgaos/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          const BOM = '\uFEFF';
          var FileSaver = require('file-saver');
          var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
          var data = moment(new Date()).format("DD/MM/YYYY")
          FileSaver.saveAs(blob, 'relatorio_empresas_orgaos_' + data + '.csv');
          toast.success('Download do arquivo realizado com sucesso!')
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
          dispatch(clear())
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}
export function changeRelatorioList(relatorio, totalRows) {
  return {
    type: 'FETCHED_ALL_RELATORIO',
    relatorio: relatorio,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'RELATORIO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'RELATORIO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'RELATORIO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function isLoading() {
  return {
    type: 'RELATORIO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'RELATORIO_NOTISLOADING'
  }
}
