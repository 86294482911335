import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import {
  Box,
  Card,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { withRouter } from 'react-router-dom'
import { Page } from 'components'
import { EmpresaCadastroEdit } from './empresaCadastroEdit.component'
import {
  cidadeAction,
  empresaAction,
  empresaAnexoAction,
  tipoAnexoAction,
  estadoAction,
  tipoVeiculoAction,
  tipoServicoAction,
  orgaoAction,
  authActions
} from '../../_actions'
import { EmpresaAnexoEdit } from './empresaAnexoEdit.component'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const EmpresaEdit = (props) => {

  const { classes, empresa, className, ...rest } = props
  const { match: { params } } = props;

  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    dispatch(empresaAction.clear())
    dispatch(tipoVeiculoAction.getTiposVeiculo())
    dispatch(estadoAction.getEstadoSelect())
    dispatch(tipoServicoAction.getTiposServico())
    dispatch(orgaoAction.getOrgaoInteresseSelect())

    if (params.id) {
      dispatch(empresaAction.getEmpresaById(params.id))
      dispatch(tipoAnexoAction.getTiposAnexosEmpresaSelect())
      dispatch(empresaAnexoAction.getEmpresaAnexo({}, true));
    } else {
      dispatch(empresaAction.clear())
    }
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Page
      className={classes.root}
      title={params.id ? 'Gerência de Empresas - Editar Empresa' : 'Gerência de Empresas - Nova Empresa'}
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                  <Tab label="CADASTRO" {...a11yProps(0)} />
                  <Tab label="ANEXOS" {...a11yProps(1)} disabled={authActions.isPrefeitura()} />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0} >
                  <EmpresaCadastroEdit props={props} />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <EmpresaAnexoEdit props={props} />
              </TabPanel>
            </Card>
          </Grid >
        </Grid >
      </Grid >
    </Page >
  )
}

EmpresaEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedEmpresaEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(EmpresaEdit))
)
export { connectedEmpresaEditPage as EmpresaEdit }
