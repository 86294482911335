const initialState = {
  totalRegistros: 0,
  isLoading: false,
  ordemServico: [],
  open: false,
  id: '',
  descritivo: '',
  titulo: '',
  idTipoServico: 0,
  tipoServico: [],
  idVeiculo: 0,
  idUsuario: 0,
  data: '',
  avaliacao: 0,
  descritivoAvaliacao: '',
  idUsuarioAvaliacao: 0,
  idOrgao: 0,
  idEmpresa: 0,
  ultimoEvento: '',
  emergencia: false,
  mecanicoDelivery: false,
  motivoExclusao: '',
  latitude: '',
  longitude: '',
  endereco: '',
  garantia: false,
  orgao: [],
  empresa: [],
  veiculo: [],
  usuario: [],
  eventos: [],
  ordensServicosFotos: [],
  ordensServicosOrcamentos: [],
  ordensServicosOcorrencias: [],
  ordensServicosEmpenhos: []
}

export function ordemServico(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_ORDEMSERVICO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_ORDEMSERVICO_SUCCESS':
      return {
        ...state,
        id: action.ordemServico.id,
        isLoading: false,
        idTipoServico: action.ordemServico.idTipoServico,
        tipoServico: action.ordemServico.tipoServico,
        descritivo: action.ordemServico.descritivo,
        titulo: action.ordemServico.titulo,
        idVeiculo: action.ordemServico.idVeiculo,
        idUsuario: action.ordemServico.idUsuario,
        data: action.ordemServico.data,
        avaliacao: action.ordemServico.avaliacao,
        descritivoAvaliacao: action.ordemServico.descritivoAvaliacao,
        idUsuarioAvaliacao: action.ordemServico.idUsuarioAvaliacao,
        idOrgao: action.ordemServico.idOrgao,
        idEmpresa: action.ordemServico.idEmpresa,
        ultimoEvento: action.ordemServico.ultimoEvento,
        emergencia: action.ordemServico.emergencia,
        mecanicoDelivery: action.ordemServico.mecanicoDelivery,
        orgao: action.ordemServico.orgao,
        motivoExclusao: action.ordemServico.motivoExclusao,
        latitude: action.ordemServico.latitude,
        longitude: action.ordemServico.longitude,
        endereco: action.ordemServico.endereco,
        garantia: action.ordemServico.garantia,
        empresa: action.ordemServico.empresa,
        eventos: action.ordemServico.eventos,
        usuario: action.ordemServico.usuario,
        veiculo: action.ordemServico.veiculo,
        ordensServicosFotos: action.ordemServico.ordensServicosFotos,
        ordensServicosOrcamentos: action.ordemServico.ordensServicosOrcamentos,
        ordensServicosOcorrencias: action.ordemServico.ordensServicosOcorrencias,
        ordensServicosEmpenhos: action.ordemServico.ordensServicosEmpenhos
      }
    case 'CREATE_ORDEMSERVICO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_ORDEMSERVICO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_ORDEMSERVICO_SUCCESS':
      return {
        ...state,
        id: action.ordemServico.id,
        isLoading: false,
        idTipoServico: action.ordemServico.idTipoServico,
        tipoServico: action.ordemServico.tipoServico,
        descritivo: action.ordemServico.descritivo,
        titulo: action.ordemServico.titulo,
        idVeiculo: action.ordemServico.idVeiculo,
        idUsuario: action.ordemServico.idUsuario,
        data: action.ordemServico.data,
        avaliacao: action.ordemServico.avaliacao,
        descritivoAvaliacao: action.ordemServico.descritivoAvaliacao,
        idUsuarioAvaliacao: action.ordemServico.idUsuarioAvaliacao,
        idOrgao: action.ordemServico.idOrgao,
        idEmpresa: action.ordemServico.idEmpresa,
        ultimoEvento: action.ordemServico.ultimoEvento,
        emergencia: action.ordemServico.emergencia,
        mecanicoDelivery: action.ordemServico.mecanicoDelivery,
        orgao: action.ordemServico.orgao,
        motivoExclusao: action.ordemServico.motivoExclusao,
        empresa: action.ordemServico.empresa,
        eventos: action.ordemServico.eventos,
        usuario: action.ordemServico.usuario,
        veiculo: action.ordemServico.veiculo,
        latitude: action.ordemServico.latitude,
        longitude: action.ordemServico.longitude,
        endereco: action.ordemServico.endereco,
        garantia: action.ordemServico.garantia,
        ordensServicosFotos: action.ordemServico.ordensServicosFotos,
        ordensServicosOrcamentos: action.ordemServico.ordensServicosOrcamentos,
        ordensServicosOcorrencias: action.ordemServico.ordensServicosOcorrencias,
        ordensServicosEmpenhos: action.ordemServico.ordensServicosEmpenhos
      }
    case 'EDIT_ORDEMSERVICO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_ORDEMSERVICO':
      return {
        ...state,
        ordemServico: action.ordemServico,
        totalRegistros: action.totalRegistros
      }
    case 'ORDEMSERVICO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        idTipoServico: action.idTipoServico,
        tipoServico: action.tipoServico,
        descritivo: action.descritivo,
        titulo: action.titulo,
        idVeiculo: action.idVeiculo,
        idUsuario: action.idUsuario,
        data: action.data,
        avaliacao: action.avaliacao,
        descritivoAvaliacao: action.descritivoAvaliacao,
        idUsuarioAvaliacao: action.idUsuarioAvaliacao,
        idOrgao: action.idOrgao,
        idEmpresa: action.idEmpresa,
        ultimoEvento: action.ultimoEvento,
        emergencia: action.emergencia,
        mecanicoDelivery: action.mecanicoDelivery,
        motivoExclusao: action.motivoExclusao,
        orgao: action.orgao,
        empresa: action.empresa,
        eventos: action.eventos,
        usuario: action.usuario,
        veiculo: action.veiculo,
        latitude: action.latitude,
        longitude: action.longitude,
        endereco: action.endereco,
        garantia: action.garantia,
        ordensServicosFotos: action.ordensServicosFotos,
        ordensServicosOrcamentos: action.ordensServicosOrcamentos,
        ordensServicosOcorrencias: action.ordensServicosOcorrencias,
        ordensServicosEmpenhos: action.ordensServicosEmpenhos,
      }
    case 'ORDEMSERVICO_CLEAR':
      return initialState
    case 'ORDEMSERVICO_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        ordemServico: [...state.ordemServico, action.data.ordemServico]
      }
    case 'ORDEMSERVICO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'ORDEMSERVICO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'ORDEMSERVICO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
