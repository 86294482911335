import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const veiculoAction = {
  getVeiculo,
  getVeiculosTelemetria,
  getResumoFrotaVeiculo,
  getVeiculosGestaoAbastecimento,
  getVeiculoSelect,
  addVeiculo,
  getVeiculoById,
  getVeiculoTelemetriaByPlaca,
  getVeiculoOcorrencias,
  onChangeProps,
  editVeiculoInfo,
  editVeiculosDetails,
  editVeiculoOrgaoInfo,
  createVeiculo,
  createVeiculoOrgao,
  changeDetailsVeiculo,
  deleteVeiculoById,
  deleteVeiculoOrgaoById,
  clear,
  clearAll,
  clearUsuariosVeiculos,
  isLoading,
  notIsLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getVeiculo(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'veiculos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeVeiculosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getVeiculosGestaoAbastecimento(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'gestaoAbastecimento/veiculos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeVeiculosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getVeiculoTelemetriaByPlaca(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'telemetria/veiculo/placa' + stringUtils.serialize(filterModel);;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeVeiculosList(response.data))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getVeiculosTelemetria(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'telemetria/veiculos' + stringUtils.serialize(filterModel);;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeVeiculosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getResumoFrotaVeiculo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'telemetria/resumo/frotaperiodo' + stringUtils.serialize(filterModel);;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeVeiculosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

function getVeiculoOcorrencias(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'telemetria/veiculos/placa' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeVeiculosOcorrenciasList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        dispatch(notIsLoading())
        // return err.response;
      })
  }
}

function getVeiculoSelect(idOrgao) {
  return (dispatch) => {
    let apiEndpoint = 'veiculos/select/' + idOrgao;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeVeiculosList(response.data, 0))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function createVeiculo(payload, props) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'veiculos/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createVeiculoInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/veiculos') // retorna para a lista e atualiza
        } else {
          toast.error('Oops! Erro ao cadastrar veículo! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar veículo! 😥')
        return err.response;
      })
  }
}

function createVeiculoOrgao(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'veiculos/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createVeiculoInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())

          let filterModel = {
            Page: 0,
            Limit: 10,
            Term: '',
            IdOrgao: payload.idOrgao
          };

          dispatch(veiculoAction.getVeiculo(filterModel, true));
        } else {
          toast.error('Oops! Erro ao cadastrar veículo! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar veículo! 😥')
        return err.response;
      })
  }
}

function changeDetailsVeiculo(veiculo) {
  return (dispatch) => {
    if (veiculo) {
      dispatch(onChangeDetailsVeiculo(veiculo))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsVeiculo(veiculo) {
  return [
    {
      type: 'CHANGE_DETAILS_VEICULO',
      id: veiculo.id,
      placa: veiculo.placa
    }
  ]
}

function getVeiculoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'veiculos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editVeiculosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editVeiculoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'veiculos/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedVeiculoInfo())
          dispatch(clear())
          dispatch(notIsLoading())
          dispatch(getVeiculoById(id))
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao atualizar veiculo! 😥')
        return err.response;
      })
  }
}

function editVeiculoOrgaoInfo(id, payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'veiculos/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedVeiculoInfo())
          dispatch(clear())
          dispatch(notIsLoading())

          let filterModel = {
            Page: 0,
            Limit: 10,
            Term: '',
            IdOrgao: payload.idOrgao
          };

          dispatch(veiculoAction.getVeiculo(filterModel, true));
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar veiculo! 😥')
        return err.response;
      })
  }
}

function deleteVeiculoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'veiculos/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteVeiculosDetails())
      history.push('/veiculos')
      dispatch(getVeiculo(filterModel))
      dispatch(notIsLoading())
    })
  }
}

function deleteVeiculoOrgaoById(id, idOrgao) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'veiculos/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteVeiculosDetails())
      let filterModel = {
        Page: 0,
        Limit: 10,
        Term: '',
        IdOrgao: idOrgao
      };

      dispatch(getVeiculo(filterModel))
      dispatch(notIsLoading())
    })
  }
}

export function addVeiculo(payload, props) {
  return [{ type: 'ADD_VEICULO', veiculo: payload },
  clear()
  ]
}

export function changeVeiculosList(veiculo, totalRows) {
  return {
    type: 'FETCHED_ALL_VEICULO',
    veiculo: veiculo,
    totalRegistros: totalRows
  }
}

export function changeVeiculosOcorrenciasList(veiculo, totalRows) {
  return {
    type: 'FETCHED_ALL_VEICULO_OCORRENCIAS',
    veiculoOcorrencias: veiculo,
    totalRegistrosOcorrencias: totalRows
  }
}

export function clear() {
  return {
    type: 'VEICULO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'VEICULO_CLEAR_ALL'
  }
}

export function clearUsuariosVeiculos() {
  return {
    type: 'VEICULO_CLEAR_USUARIOS_VEICULOS'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'VEICULO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editVeiculosDetails(veiculo) {
  return {
    type: 'VEICULO_DETAIL',
    id: veiculo.id,
    ano: veiculo.ano,
    placa: veiculo.placa,
    renavam: veiculo.renavam,
    hodometro: veiculo.hodometro,
    numeroChassis: veiculo.numeroChassis,
    idOrgao: veiculo.idOrgao,
    idSubOrgao: veiculo.idSubOrgao,
    idMarca: veiculo.idMarca,
    idTipoVeiculo: veiculo.idTipoVeiculo,
    idTipoCombustivel: veiculo.idTipoCombustivel,
    modelo: veiculo.modelo,
    orgao: veiculo.orgao,
    subOrgao: veiculo.subOrgao,
    marca: veiculo.marca,
    caminhoFoto: veiculo.caminhoFoto,
    usuariosVeiculos: veiculo.usuariosVeiculos,
    telemetria: veiculo.telemetria,
    gestaoAbastecimento: veiculo.gestaoAbastecimento,
    excluido: veiculo.excluido
  }
}

export function updatedVeiculoInfo() {
  return {
    type: 'VEICULO_UPDATED'
  }
}

export function createVeiculoInfo() {
  return {
    type: 'VEICULO_CREATED_SUCCESSFULLY'
  }
}

export function deleteVeiculosDetails() {
  return {
    type: 'DELETED_VEICULO_DETAILS'
  }
}

export function isLoading() {
  return {
    type: 'VEICULO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'VEICULO_NOTISLOADING'
  }
}
