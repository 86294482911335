import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import getInitials from 'utils/getInitials'
import HelpIcon from '@mui/icons-material/Help';
import { withStyles } from '@mui/styles'
import Swal from 'sweetalert2';
import Typography from '@mui/material/Typography'
import CustomButton from '../../components/CustomButton/CustomButton'
import { useDebounce } from 'use-debounce';
import moment from 'moment'
import {
  Autocomplete,
  Backdrop,
  Badge,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Switch,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { styled } from '@mui/material/styles';
import {
  authActions,
  veiculoAction,
  usuarioAction,
  orgaoAction,
  marcaAction,
  subOrgaoAction,
  tipoCombustivelAction,
  tipoVeiculoAction,
  relatorioAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { uploadImage } from '_services'
import { useConfirm } from 'material-ui-confirm'
import { errorsUploadImage } from 'utils'
import { history, stringUtils, placaMask, MANUAIS_CATEGORIAS } from '../../utils'
import { Page, ImageUploader } from 'components'
import { isEmpty } from 'lodash'
import SaveIcon from '@mui/icons-material/Save';
import { HelpModal, EditorHeader } from '../../components';

const SwitchBlack = styled(Switch)(({ theme }) => ({
  alignItems: 'top',
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#000000',
    }
  }
}));

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(1),
    justifyContent: 'space-between'
  },
  uploadButtonCenter: {
    marginRight: theme.spacing(1),
    justifyContent: 'center'
  },
  avatar: {
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer',
    objectFit: 'cover'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const VeiculoEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, veiculo, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [idUsuario, setIdUsuario] = useState(0);

  const confirm = useConfirm()
  const [images, setImages] = useState([])
  const [imagem, setImagem] = useState('')
  const [open, setOpen] = useState(false)

  const getTodayDate = (): string => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const [order] = useState('data');
  const [direction] = useState('desc');
  const [dataInicio, setDataInicio] = useState();
  const [dataFim, setDataFim] = useState(getTodayDate());

  const [dataInicioOcorrencia, setDataInicioOcorrencia] = useState();
  const [dataFimOcorrencia, setDataFimOcorrencia] = useState(getTodayDate());

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const veiculoTelemetria = useSelector(
    (state) => state.veiculo.veiculo
  )

  const [textUsuario, setTextUsuario] = useState('');

  const [valueOrgao, setValueOrgao] = useState(null);
  const [inputValueOrgao, setInputValueOrgao] = useState('');

  const [valueMarca, setValueMarca] = useState(null);
  const [inputValueMarca, setInputValueMarca] = useState('');

  const [valueTipoCombustivel, setValueTipoCombustivel] = useState(null);
  const [inputValueTipoCombustivel, setInputValueTipoCombustivel] = useState('');

  const [valueUsuario, setValueUsuario] = useState(null);
  const [inputValueUsuario, setInputValueUsuario] = useState('');

  const tiposVeiculos = useSelector((state) => state.tipoVeiculo.tipoVeiculo)

  const { relatorio } = props.relatorio

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const totalRegistros = relatorio?.length

  const [pageOcorrencias, setPageOcorrencias] = useState(0);
  const [limitOcorrencias, setLimitOcorrencias] = useState(10);
  const [termOcorrencias, setTermOcorrencias] = useState('');
  const [debounceTermOcorrencias] = useDebounce(termOcorrencias, 1000);
  const totalRegistrosOcorrencias = props.veiculo.totalRegistrosOcorrencias;

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const { usuarioOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const { orgao } = useSelector((state) => state.orgao)

  const { marca } = useSelector((state) => state.marca)

  const { tipoCombustivel } = useSelector((state) => state.tipoCombustivel)

  const { usuario } = useSelector((state) => state.usuario)

  const optionsOrgaos = orgao.map(u => ({ id: u.value, nome: u.text, telemetria: u.telemetria, gestaoAbastecimento: u.gestaoAbastecimento }));

  const optionsMarcas = marca.map(u => ({ id: u.value, nome: u.text }));

  const optionsCombustiveis = tipoCombustivel.map(u => ({ id: u.value, nome: u.text }));

  const optionsUsuarios = usuario.map(u => ({ id: u.value, nome: u.text }));

  const subOrgaos = useSelector((state) => state.subOrgao?.subOrgao)

  useEffect(() => {
    if (valueOrgao) {
      handleChangeAutoComplete(valueOrgao, orgao, 'orgao')
    }

  }, [valueOrgao])

  useEffect(() => {

    if (props.veiculo.placa && props.veiculo.placa != "") {
      const fetchData = (page, limit) => {

        let filterModel = {
          Page: page,
          Limit: limit,
          Order: order,
          Direction: direction,
          Placa: props.veiculo.placa
        };

        dispatch(veiculoAction.getVeiculoTelemetriaByPlaca(filterModel, true));
      };

      fetchData(page, limit, order, direction);
    }

  }, [page, limit, order, direction]);

  useEffect(() => {
    if (valueMarca) {
      handleChangeAutoComplete(valueMarca, marca, 'marca')
    }
  }, [valueMarca])

  useEffect(() => {
    if (valueTipoCombustivel) {
      handleChangeAutoComplete(valueTipoCombustivel, tipoCombustivel, 'tipoCombustivel')
    }
  }, [valueTipoCombustivel])

  useEffect(() => {
    if (valueUsuario) {
      handleChangeAutoComplete(valueUsuario, usuario, 'usuario')

      setIdUsuario(valueUsuario.id);
      setTextUsuario(valueUsuario.nome)
    }
  }, [valueUsuario])

  const handleChangeAutoComplete = (obj, array, nameArray) => {
    if (obj != null) {
      let selected = array.filter(
        (item) => item.value == obj.id
      )

      if (nameArray == 'orgao') {
        dispatch(orgaoAction.changeDetailsOrgao(selected[0]))
      } else if (nameArray == 'tipoCombustivel') {
        dispatch(tipoCombustivelAction.changeDetailsTipoCombustivel(selected[0]))
      } else if (nameArray == 'marca') {
        dispatch(marcaAction.changeDetailsMarca(selected[0]))
      } else if (nameArray == 'usuario') {
        dispatch(usuarioAction.changeDetailsUsuario(selected[0]))
      }

    } else {

    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageOcorrencias = (event, newPage) => {
    setPageOcorrencias(newPage);
  };

  const handleChangeTerm = prop => event => {
    setTerm(event.target.value);
  };

  const handleChangeTermOcorrencias = prop => event => {
    setTermOcorrencias(event.target.value);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeLimitOcorrencias = (event) => {
    setLimitOcorrencias(parseInt(event.target.value, 10));
    setPageOcorrencias(0);
  };

  const removeImage = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Não'
    }).then(() => {
      let payload = {
        ...veiculo,
        caminhoFoto: null
      }
      dispatch(
        veiculoAction.editVeiculoInfo(veiculo.id, payload, false)
      )
      var event2 = {
        type: 'select',
        target: {
          value: null
        }
      };
      dispatch(veiculoAction.onChangeProps('caminhoFoto', event2))
      setImagem(null)
    })
  }

  const onChangeImage = (imageList) => {
    // data for submit
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {

        let payload = {
          ...veiculo,
          caminhoFoto: response.toString()
        }

        if (veiculo.id !== 0) {
          dispatch(
            veiculoAction.editVeiculoInfo(veiculo.id, payload, false)
          )
        }

        setTimeout(function () {

          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(veiculoAction.onChangeProps('caminhoFoto', event2))

          setImagem(response)
          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  useEffect(() => {
    if (idOrgao != 0 && idOrgao != '') {
      if (usuarioOrgao && usuarioOrgao.length > 0) {
        let selectedOrgao = usuarioOrgao.filter(
          (item) => item.idOrgao == idOrgao
        )
        setValueOrgao(usuarioOrgao.length > 0 ? selectedOrgao[0].orgao : '')
      }
    }
  }, [idOrgao])

  useEffect(() => {
    if (params.id != undefined && props.veiculo.idOrgao != 0) {
      setValueOrgao(props.veiculo.orgao)
    }
  }, [props.veiculo.idOrgao])

  useEffect(() => {
    if (params.id != undefined && props.veiculo.idMarca != 0) {
      setValueMarca({
        'id': marca?.find(m => m.value == props.veiculo.idMarca)?.value,
        'nome': marca?.find(m => m.value == props.veiculo.idMarca)?.text
      })
    }
  }, [props.veiculo.idMarca])

  useEffect(() => {
    if (params.id != undefined && props.veiculo.idTipoCombustivel != 0) {
      setValueTipoCombustivel({
        'id': tipoCombustivel?.find(m => m.value == props.veiculo.idTipoCombustivel)?.value,
        'nome': tipoCombustivel?.find(m => m.value == props.veiculo.idTipoCombustivel)?.text
      })
    }
  }, [props.veiculo.idTipoCombustivel])

  useEffect(() => {
    setImagem(veiculo.caminhoFoto)
  }, [veiculo.caminhoFoto, open]);

  useEffect(() => {
    // forceUpdate(n => !n);
  }, [open, imagem]);

  useEffect(() => {

    if (props.veiculo?.placa) {
      const payload = {
        PlacaVeiculo: props.veiculo.placa ? props.veiculo.placa.split('-').join('').replace('-', '') : '',
        Term: term,
        Page: page,
        Limit: limit,
        DataInicio: dataInicio,
        DataFim: dataFim
      }

      dispatch(relatorioAction.getRelatorioServicosRealizados(payload, true));
    }

  }, [term, page, debounceTerm, props.veiculo?.placa, dataInicio, dataFim])

  useEffect(() => {

    dispatch(relatorioAction.clear())
    dispatch(veiculoAction.clear())
    dispatch(tipoVeiculoAction.getTipoVeiculoSelect())
    dispatch(tipoCombustivelAction.getTipoCombustivelSelect())
    dispatch(marcaAction.getMarcaSelect())
    dispatch(orgaoAction.getOrgaoSelect())
    if (params.id) {
      dispatch(veiculoAction.getVeiculoById(params.id))
    } else {
      dispatch(veiculoAction.clear())
      dispatch(veiculoAction.clearUsuariosVeiculos())
    }

    if (idOrgao != 0 && idOrgao != '') {
      dispatch(usuarioAction.getUsuarioMotoristaByOrgaoSelect(idOrgao))
    }

    if (idOrgao != undefined && idOrgao !== 0 && idOrgao !== '') {
      dispatch(subOrgaoAction.getSubOrgaoByIdOrgao(idOrgao))
    }

  }, []);

  //SÓ FAZ A CHAMADA SE POSSUIR TELEMETRIA
  useEffect(() => {
    if (params.id && props.veiculo.placa && props.veiculo.telemetria && dataInicio && dataFim) {
      const filterModel = {
        Placa: props.veiculo.placa ? props.veiculo.placa.split('-').join('').replace('-', '') : '',
        DataInicio: dataInicioOcorrencia,
        DataFim: dataFimOcorrencia,
        Term: termOcorrencias,
        Page: pageOcorrencias,
        Limit: limitOcorrencias
      }

      dispatch(veiculoAction.getVeiculoOcorrencias(filterModel))
    }
  }, [termOcorrencias, pageOcorrencias, debounceTermOcorrencias, props.veiculo?.placa, props.veiculo?.telemetria, dataInicioOcorrencia, dataFimOcorrencia])

  useEffect(() => {
    if (valueOrgao) {
      dispatch(usuarioAction.getUsuarioMotoristaByOrgaoSelect(valueOrgao.id))
      dispatch(subOrgaoAction.getSubOrgaoByIdOrgao(valueOrgao.id))
    }
  }, [valueOrgao])

  useEffect(() => {
    if (idOrgao != 0 && idOrgao != '') {
      dispatch(usuarioAction.getUsuarioMotoristaByOrgaoSelect(idOrgao))
      dispatch(subOrgaoAction.getSubOrgaoByIdOrgao(idOrgao))
    }
  }, [idOrgao]);

  useEffect(() => {
  }, [ready, props.veiculo.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(veiculoAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      ano: props.veiculo.ano,
      placa: props.veiculo.placa ? props.veiculo.placa.split('-').join('').replace('-', '') : '',
      renavam: props.veiculo.renavam,
      hodometro: props.veiculo.hodometro,
      numeroChassis: props.veiculo.numeroChassis,
      idMarca: valueMarca ? valueMarca.id : props.veiculo.idMarca,
      idTipoVeiculo: props.veiculo.idTipoVeiculo,
      idTipoCombustivel: valueTipoCombustivel ? valueTipoCombustivel.id : props.veiculo.idTipoCombustivel,
      modelo: props.veiculo.modelo,
      caminhoFoto: props.veiculo.caminhoFoto,
      telemetria: props.veiculo.telemetria,
      gestaoAbastecimento: props.veiculo.gestaoAbastecimento,
      idOrgao: valueOrgao ? valueOrgao.id : props.veiculo.idOrgao,
      idSubOrgao: (props.veiculo.idSubOrgao == '' || props.veiculo.idSubOrgao == 0) ? null : props.veiculo.idSubOrgao,
      usuariosVeiculos: props.veiculo.usuariosVeiculos
    }

    if (valueMarca != null) {
      if (valueTipoCombustivel != null) {
        if (params.id) {
          dispatch(veiculoAction.editVeiculoInfo(params.id, payload, true))
        } else {
          dispatch(veiculoAction.createVeiculo(payload))
        }
      } else {
        toast.error('Selecione um tipo de combustível')
      }
    } else {
      toast.error('Selecione uma marca')
    }

  };

  const handleAddOnList = e => {

    if (props.veiculo.usuariosVeiculos.filter(ts => ts.idUsuario == parseInt(idUsuario)).length > 0) {
      toast.error('Erro ao adicionar! Este usuário já existe!')
    }
    else {

      if (idUsuario != '' && idUsuario != '0') {
        props.veiculo.usuariosVeiculos.push({ 'idUsuario': idUsuario, 'idVeiculo': props.veiculo.id, 'usuario': { 'nome': textUsuario } });
        forceUpdate(n => !n);
        setIdUsuario(0);

      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Erro ao salvar',
          showCloseButton: true,
          showConfirmButton: true,
          html: '<div style="font-size: 14px; text-align: center">Informe ao menos um usuário!</div>'
        });
      }
    }
  };

  const handleRemoveOnList = id => {
    props.veiculo.usuariosVeiculos = props.veiculo.usuariosVeiculos.filter(ts => ts.idUsuario != parseInt(id));
    forceUpdate(n => !n);
  };

  function excludeFilter(obj) {
    if (props.veiculo.usuariosVeiculos && props.veiculo.usuariosVeiculos.length > 0) {
      if (props.veiculo.usuariosVeiculos.filter(fl => fl.idUsuario == parseInt(obj.value)).length > 0) {
        return false;
      } else {
        return true;
      }

    } else {
      return true;
    }
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Veículo'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Veículo'}</b>
      </Typography>
    )
  }


  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Veículos - Editar Veículo'
          : 'Gerência de Veículos - Novo Veículo'
      }
    >
      <ValidatorForm
        className={classes.form}
        ref={formRef}
        id="formVeiculo"
        onSubmit={(event) => handleSubmit(event)}
      >
        <Grid
          {...rest}
          className={clsx(classes.root, className)}
          container
          spacing={3}
        >
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <Grid item md={12} xs={12}>
              <Backdrop className={classes.backdrop} open={open}>
                <Grid
                  container
                  spacing={3}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                >
                  <Grid item>
                    <CircularProgress color="primary" />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" color="textPrimary">
                      Enviando foto...
                    </Typography>
                  </Grid>
                </Grid>
              </Backdrop>
              <ImageUploader
                images={images}
                onChangeImage={onChangeImage}
                onError={errorsUploadImage}
                acceptType={['jpeg', 'jpg', 'png', 'webp', 'jfif', 'bmp', 'tiff']}
                maxFileSize={5000000}
                resolutionWidth={5000}
                resolutionHeight={5000}
                resolutionType={"less"}
                removeImage={removeImage}
                props={veiculo}
                imagem={imagem}
                getInitials={getInitials}
                classes={classes}
                rest={rest}
                className={className}
              />
            </Grid>
          </Grid>
          <Grid item lg={8} md={6} xl={9} xs={12}>
            <Grid item md={12} xs={12}>
              <Card>
                <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <CardHeader
                    subheader="Cadastro de Veículos"
                    title={(
                      params.id ?
                        <EditorHeader title='Editar Veículo' tabela='Veiculo' chave={params.id} props={props} />
                        :
                        <InsertText />
                    )}
                  /> <Grid item lg={1} md={1} xl={1} xs={12}>
                    <IconButton
                      aria-label="Ajuda"
                      component="a"
                      onClick={() =>
                        handleOpenModalHelp()
                      }
                      size="small"
                    >
                      <Tooltip title={'Ajuda'}>
                        <Tooltip>
                          <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                        </Tooltip>
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="ano"
                        type="number"
                        label="Ano *"
                        margin="normal"
                        onChange={handleChange('ano')}
                        value={props.veiculo.ano || ''}
                        variant="outlined"
                        validators={['required', 'maxStringLength: 4']}
                        errorMessages={['Campo obrigatório!', 'Ano inválido!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="placa"
                        label="Placa *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('placa')}
                        value={props.veiculo.placa ? placaMask(props.veiculo.placa) : '' || ''}
                        variant="outlined"
                        validators={['required', 'maxStringLength: 8']}
                        errorMessages={['Campo obrigatório!', 'Placa só pode ter no máximo 7 caracteres!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="hodometro"
                        type="number"
                        label="Hodômetro"
                        margin="normal"
                        onChange={handleChange('hodometro')}
                        value={props.veiculo.hodometro || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12} style={{ marginTop: 16 }}>
                      <Autocomplete
                        value={valueMarca}
                        onChange={(event, newValue) => {
                          setValueMarca(newValue);
                        }}
                        inputValue={inputValueMarca}
                        onInputChange={(event, newInputValueMarca) => {
                          setInputValueMarca(newInputValueMarca);
                        }}
                        id="controllable-states-demo"
                        options={optionsMarcas}
                        margin="normal"
                        variant="outlined"
                        defaultValue={valueMarca}
                        getOptionLabel={(optionMarca) => (optionMarca ? optionMarca.nome : '')}
                        renderInput={(params) => <TextField {...params} label="Marca * " fullWidth />}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="modelo"
                        InputLabelProps={{
                          shrink: true
                        }}
                        label="Modelo *"
                        onInput={stringUtils.toInputUppercase}
                        margin="normal"
                        onChange={handleChange('modelo')}
                        value={props.veiculo.modelo || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        variant="outlined"
                        id="idTipoVeiculo"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        onChange={handleChange('idTipoVeiculo')}
                        label="Tipo do Veículo *"
                        value={props.veiculo.idTipoVeiculo || ''}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idTipoVeiculo"
                      >
                        <MenuItem disabled value="">
                          <em>Tipo do Veículo *</em>
                        </MenuItem>
                        {!isEmpty(tiposVeiculos) &&
                          undefined !== tiposVeiculos &&
                          tiposVeiculos.length &&
                          tiposVeiculos.map((row, index) => (
                            <MenuItem key={index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className={clsx(classes.root, className)}
          style={{ marginTop: 0, paddingTop: 0 }}
          container
        >
          <Card>
            <Grid
              {...rest}
              className={clsx(classes.root, className)}
              container
              spacing={2}
            >
              <Grid item lg={6} md={6} xl={6} xs={12} style={{ marginTop: 16 }}>
                <Autocomplete
                  value={valueTipoCombustivel}
                  onChange={(event, newValue) => {
                    setValueTipoCombustivel(newValue);
                  }}
                  inputValue={inputValueTipoCombustivel}
                  onInputChange={(event, newInputValueTipoCombustivel) => {
                    setInputValueTipoCombustivel(newInputValueTipoCombustivel);
                  }}
                  id="controllable-states-demo"
                  options={optionsCombustiveis}
                  margin="normal"
                  variant="outlined"
                  defaultValue={valueTipoCombustivel}
                  getOptionLabel={(optionTipoCombustivel) => (optionTipoCombustivel ? optionTipoCombustivel.nome : '')}
                  renderInput={(params) => <TextField {...params} label="Combustível * " fullWidth />}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={6} xl={6} xs={12}>
                <TextValidator
                  className={classes.textField}
                  fullWidth
                  id="renavam"
                  label="Renavam"
                  margin="normal"
                  onChange={handleChange('renavam')}
                  value={props.veiculo.renavam || ''}
                  variant="outlined"
                  validators={['maxStringLength: 11']}
                  errorMessages={['Renavam só pode ter no máximo 11 caracteres!']}
                />
              </Grid>
              <Grid item lg={6} md={6} xl={6} xs={12}>
                <TextValidator
                  className={classes.textField}
                  fullWidth
                  id="numeroChassis"
                  label="Número Chassis *"
                  margin="normal"
                  onInput={stringUtils.toInputUppercase}
                  validators={['required', 'maxStringLength: 17']}
                  errorMessages={['Campo obrigatório', 'Número Chassis só pode ter no máximo 17 caracteres!']}
                  onChange={handleChange('numeroChassis')}
                  value={props.veiculo.numeroChassis || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item lg={6} md={6} xl={6} xs={12} style={{ paddingTop: 31 }}>
                <Autocomplete
                  value={valueOrgao}
                  onChange={(event, newValue) => {
                    setValueOrgao(newValue);
                  }}
                  inputValue={inputValueOrgao}
                  onInputChange={(event, newInputValueOrgao) => {
                    setInputValueOrgao(newInputValueOrgao);
                  }}
                  id="controllable-states-demo"
                  options={optionsOrgaos}
                  margin="normal"
                  disabled={(idOrgao != null && idOrgao != 0 && idOrgao != '')}
                  variant="outlined"
                  defaultValue={valueOrgao}
                  getOptionLabel={(optionOrgao) => (optionOrgao ? optionOrgao.nome : '')}
                  renderInput={(params) => <TextField {...params} label="Órgão * " fullWidth />}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={6} xl={6} xs={12}>
                <SelectValidator
                  className={classes.textField}
                  variant="outlined"
                  id="idSubOrgao"
                  label="Sub Órgão"
                  value={props.veiculo.idSubOrgao}
                  onChange={handleChange('idSubOrgao')}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  displayEmpty
                  labelId="idSubOrgao"
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {!isEmpty(subOrgaos) &&
                    undefined !== subOrgaos &&
                    subOrgaos.length &&
                    subOrgaos.map((row, index) => (
                      <MenuItem key={index} value={row.value}>
                        {row.text}
                      </MenuItem>
                    ))}
                </SelectValidator>
              </Grid>
              <Grid item lg={6} md={6} xl={6} xs={12} style={{ alignItems: 'top', justifyContent: 'space-between' }}>
                {/*se o orgao não tiver telemetria habilitada,
                        mostra o campo desabilitado*/}
                {authActions.isGestor() ?
                  <FormControlLabel
                    control={
                      <SwitchBlack
                        color="default"
                        disabled={valueOrgao?.telemetria != true}
                        checked={props.veiculo.telemetria}
                        onChange={handleChange('telemetria')}
                      />
                    }
                    label="Possui telemetria"
                  />
                  : null}
                {/* precisa ter telemetria habilitada e retornar dados para exibir essa caixa*/}
                {props.veiculo.telemetria ?
                  veiculoTelemetria && veiculoTelemetria != "" ?
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F9F9F9', padding: 9, borderRadius: 8, lineHeight: 1.2 }}>
                      <label key={veiculoTelemetria.id_Equipamento}>
                        <strong>Código Equipamento:</strong> {veiculoTelemetria.id_Equipamento ? veiculoTelemetria.id_Equipamento : '-'}
                      </label>
                      <label key={veiculoTelemetria.descricao}>
                        <strong>Descrição:</strong> {veiculoTelemetria.descricao ? veiculoTelemetria.descricao : '-'}
                      </label>
                      <label key={veiculoTelemetria.serial}>
                        <strong>Serial:</strong> {veiculoTelemetria.serial ? veiculoTelemetria.serial : '-'}
                      </label>
                      <label key={veiculoTelemetria.tipoCarro}>
                        <strong>Tipo de Carro:</strong> {veiculoTelemetria.tipoCarro ? veiculoTelemetria.tipoCarro : '-'}
                      </label>
                      <label key={veiculoTelemetria.categCNH}>
                        <strong>Categoria CNH:</strong> {veiculoTelemetria.categCNH ? veiculoTelemetria.categCNH : '-'}
                      </label>
                      <label key={veiculoTelemetria.limiteVelocidade}>
                        <strong>Limite Velocidade:</strong> {veiculoTelemetria.limiteVelocidade ? (veiculoTelemetria.limiteVelocidade + ' km/h') : '-'}
                      </label>
                      <label key={veiculoTelemetria.bloqueioIButton}>
                        <strong>Bloqueio IButton:</strong> {veiculoTelemetria.bloqueioIButton ? 'SIM' : 'NÃO'}
                      </label>
                      <label key={veiculoTelemetria.dataColeta}>
                        <strong>Data Coleta:</strong> {veiculoTelemetria.dataColeta ? moment(veiculoTelemetria.dataColeta).format("DD/MM/YYYY") : '-'}
                      </label>
                    </div>
                    : null : null}
              </Grid>
              <Grid item lg={6} md={6} xl={6} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                {/*se o orgao não tiver gestão de abastecimento habilitado,
                        mostra o campo desabilitado*/}
                {authActions.isGestor() ?
                  <FormControlLabel
                    control={
                      <SwitchBlack
                        color="default"
                        disabled={valueOrgao?.gestaoAbastecimento != true}
                        checked={props.veiculo.gestaoAbastecimento}
                        onChange={handleChange('gestaoAbastecimento')}
                      />
                    }
                    label="Possui Gestão de Abastecimento"
                  />
                  : null}
              </Grid>
              {(valueOrgao == undefined && idOrgao == '') || (params.id && (props.veiculo.idOrgao == undefined && idOrgao == '')) ?
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <Typography style={{ fontSize: 12, paddingLeft: 6, paddingTop: 6 }}>
                    <b>Selecione um órgão para carregar os motoristas e sub órgãos vinculados a ele</b>
                  </Typography>
                </Grid>
                : null
              }
              <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                <Divider />
              </Grid>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Typography component="h4" variant="subtitle" align="center">
                  Motoristas vinculados ao veículo
                </Typography>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: 6 }}>
                <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center', marginLeft: 20 }} >
                  <Autocomplete
                    value={valueUsuario}
                    onChange={(event, newValue) => {
                      setValueUsuario(newValue);
                    }}
                    inputValue={inputValueUsuario}
                    onInputChange={(event, newInputValueUsuario) => {
                      setInputValueUsuario(newInputValueUsuario);
                    }}
                    id="controllable-states-demo"
                    options={optionsUsuarios}
                    margin="normal"
                    variant="outlined"
                    defaultValue={valueUsuario}
                    getOptionLabel={(optionUsuario) => (optionUsuario ? optionUsuario.nome : '')}
                    renderInput={(params) => <TextField {...params} label="Selecione o Motorista " fullWidth />}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                  <Button style={{ width: '200px', marginLeft: '15px', backgroundColor: '#000' }} name="btnUsuarios" id="btnUsuarios" variant="contained" className="btn" component='a' onClick={handleAddOnList}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                </Grid>
                <Grid item lg={3} md={3} xl={3} xs={12}>
                  <div className="selectgroup selectgroup-pills" style={{ marginLeft: 20, alignItems: 'top' }}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      {
                        (props.veiculo.usuariosVeiculos != null && props.veiculo.usuariosVeiculos.length > 0 ?
                          props.veiculo.usuariosVeiculos.map(n => {
                            return (
                              (n.id !== '' ?
                                <Button style={{ fontWeigth: 400, backgroundColor: '#F2D647', marginLeft: 7, marginRight: 7 }} onClick={() => handleRemoveOnList(n.idUsuario)} key={'button' + n.id}>
                                  <Badge style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7, color: '#000' }}>
                                    {(n.usuario ? n.usuario.nome : n.idUsuario)}
                                  </Badge>
                                  &nbsp;<FontAwesomeIcon icon={faTrashAlt} style={{ color: '#000' }} />
                                </Button>
                                : null)
                            );
                          })
                          : null)
                      }
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlign: 'right',
                    justifyContent: 'end'
                  }}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => history.push('/veiculos')}
                    >
                      Cancelar
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      dark={true}
                      disabled={props.veiculo.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.veiculo.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.veiculo.isLoading ? 'Atualizando veículo...' : 'Atualizar') : (props.veiculo.isLoading ? 'Salvando veículo...' : 'Salvar')}
                    </CustomButton>
                  </Grid>
                </CardActions>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {params.id ?
          <Grid
            {...rest}
            className={clsx(classes.root, className)}
            container
            spacing={3}
          >
            <Grid item sm={12} md={12} xs={12} xl={12} style={{ marginTop: 20 }}>
              <Card>
                <CardHeader
                  subheader="Histórico do veículo"
                  title={
                    <Typography variant="h5">
                      <b>{'Serviços realizados para o veículo'}</b>
                    </Typography>
                  }
                />
                <Divider />
                <Grid item lg={12} md={12} xl={12} xs={12} style={{
                  justifyContent: 'left', alignContent: 'center',
                  marginLeft: '12px', marginRight: '12px',
                }}>
                  <TableContainer>
                    <Grid item xl={4} lg={4} md={6} xs={12} style={{ marginTop: '12px' }}>
                      <Input
                        id="term"
                        label="term"
                        placeholder="Filtro"
                        value={term}
                        onChange={handleChangeTerm('term')}
                        fullWidth
                      />
                    </Grid>
                    <br />
                    <Grid container spacing={2} style={{ justifyContent: 'right' }}>
                      <Grid item xl={4} lg={4} md={4} xs={12} style={{ marginTop: '12px' }}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="data"
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="date"
                          label="Data de Início *"
                          margin="normal"
                          onChange={(e) => setDataInicio(e.target.value)}
                          value={dataInicio || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} xs={12} style={{ marginTop: '12px' }}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="data"
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="date"
                          label="Data de Fim"
                          margin="normal"
                          onChange={(e) => setDataFim(e.target.value)}
                          value={dataFim || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell><strong>ORDEM DE SERVIÇO</strong></TableCell>
                          <TableCell><strong>DATA</strong></TableCell>
                          <TableCell><strong>EMPRESA</strong></TableCell>
                          <TableCell align="center"><strong>VALOR</strong></TableCell>
                          <TableCell align="center"><strong>AÇÃO</strong></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {relatorio && relatorio.length > 0
                          && relatorio != null ? relatorio.map((n) => {
                            return (
                              <TableRow hover >
                                <TableCell lg={12} md={12} xl={12} xs={12}
                                  style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                  {n.tituloOrdemServico ? n.tituloOrdemServico.toUpperCase() : ''}
                                </TableCell>
                                <TableCell lg={12} md={12} xl={12} xs={12}
                                  style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                  {n.dataOrdemServico ? moment(n.dataOrdemServico).format("DD/MM/yyyy") : '-'}
                                </TableCell>
                                <TableCell lg={12} md={12} xl={12} xs={12}
                                  style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                  {n.nomeEmpresa ? n.nomeEmpresa.toUpperCase() : ''}
                                </TableCell>
                                <TableCell lg={12} md={12} xl={12} xs={12}
                                  style={{ justifyContent: 'right', alignContent: 'bottom', alignItems: 'center' }}>
                                  {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                                </TableCell>
                                <TableCell lg={12} md={12} xl={12} xs={12} align="center">
                                  <IconButton
                                    aria-label="Visualizar"
                                    component="a"
                                    onClick={() =>
                                      window.open(`/ordemServico/${n.idOrdemServico}`, '_blank')
                                    }
                                    size="small"
                                  >
                                    <Tooltip title={'Visualizar Ordem de Serviço'}>
                                      <Tooltip >
                                        <RemoveRedEyeIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )
                          })
                          : <TableRow>
                            <TableCell colSpan={5}>
                              <Typography align="center" variant="subtitle2">
                                Nenhum serviço realizado até o momento
                              </Typography>
                            </TableCell>
                          </TableRow>}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    count={totalRegistros}
                    page={page}
                    onPageChange={handleChangePage}
                    labelRowsPerPage="Linhas por página"
                    labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeLimit}
                  />
                </Grid>
                <Divider />
              </Card>
            </Grid>
            {props.veiculo.telemetria ?
              <Grid item sm={12} md={12} xs={12} xl={12} style={{ marginTop: 20 }}>
                <Card>
                  <CardHeader
                    subheader="Ocorrências do veículo"
                    title={
                      <Typography variant="h5">
                        <b>{'Ocorrências registradas para o veículo'}</b>
                      </Typography>
                    }
                  />
                  <Divider />
                  <Grid item lg={12} md={12} xl={12} xs={12} align="right" style={{
                    justifyContent: 'end', alignContent: 'end', marginLeft: '12px', marginRight: '12px',
                  }}>
                    <TableContainer>
                      <Grid container spacing={2} style={{ justifyContent: 'right' }}>
                        <Grid item xl={4} lg={4} md={4} xs={12} style={{ marginTop: '12px' }}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="data"
                            InputLabelProps={{
                              shrink: true
                            }}
                            type="date"
                            label="Data de Início *"
                            margin="normal"
                            onChange={(e) => setDataInicioOcorrencia(e.target.value)}
                            value={dataInicioOcorrencia || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} xs={12} style={{ marginTop: '12px' }}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="data"
                            InputLabelProps={{
                              shrink: true
                            }}
                            type="date"
                            label="Data de Fim *"
                            margin="normal"
                            onChange={(e) => setDataFimOcorrencia(e.target.value)}
                            value={dataFimOcorrencia || ''}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell><strong>OCORRÊNCIA</strong></TableCell>
                            <TableCell><strong>CONDUTOR</strong></TableCell>
                            <TableCell align="center"><strong>TEMPO DE DURAÇÃO</strong></TableCell>
                            <TableCell><strong>DATA/HORA</strong></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {props.veiculo.veiculoOcorrencias && props.veiculo.veiculoOcorrencias.length > 0
                            && props.veiculo.veiculoOcorrencias && props.veiculo.veiculoOcorrencias != null ? props.veiculo.veiculoOcorrencias && props.veiculo.veiculoOcorrencias.map((n) => {
                              return (
                                <TableRow hover >
                                  <TableCell component="th" scope="row">
                                    {n.nome_Ocorrencia ? n.nome_Ocorrencia.toUpperCase() : ''}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {n.condutor ? n.condutor.toUpperCase() : '-'}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="center">
                                    {n.tempoSec ? n.tempoSec + ' segundos' : '-'}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {n.dataOcorrencia ? moment(n.dataOcorrencia).format("DD/MM/yyyy HH:mm:ss") : '-'}
                                  </TableCell>
                                </TableRow>
                              )
                            })
                            : <TableRow>
                              <TableCell colSpan={4}>
                                <Typography align="center" variant="subtitle2">
                                  {dataInicio != null && dataInicio != '' ? 'Nenhuma ocorrência registrada nesta data' : 'Informe uma data para realizar a pesquisa'}
                                </Typography>
                              </TableCell>
                            </TableRow>}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      component="div"
                      count={totalRegistrosOcorrencias}
                      page={pageOcorrencias}
                      onPageChange={handleChangePageOcorrencias}
                      labelRowsPerPage="Linhas por página"
                      labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistrosOcorrencias}`}
                      rowsPerPage={limitOcorrencias}
                      onRowsPerPageChange={handleChangeLimitOcorrencias}
                    />
                  </Grid>
                  <Divider />
                </Card>
              </Grid>
              : null}
          </Grid>
          : null}
        {
          (modalHelp ?
            <HelpModal
              toggle={toggleModalHelp}
              modal={modalHelp}
              props={props}
              tela={MANUAIS_CATEGORIAS.CADASTRO_VEICULO}
            />
            :
            null
          )
        }
      </ValidatorForm>
    </Page >
  )
}

VeiculoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedVeiculoEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(VeiculoEdit))
)
export { connectedVeiculoEditPage as VeiculoEdit }
