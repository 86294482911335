import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { authActions, empresaAction } from '../../_actions'
import PropTypes from 'prop-types'
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Paper,
  Skeleton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { history, MANUAIS_CATEGORIAS } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';
import { styled } from '@mui/material/styles';
import Stars from '../../components/Stars/Stars';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: '13px !important'
  }
})

const ButtonFilter = styled(Button)({
  color: '#000',
  border: `1px solid #000`,
  '&:hover': {
    backgroundColor: '#FFC107',
    border: `1px solid #000`,
  },
  "&&:focus": {
    border: `1px solid #000`,
    backgroundColor: '#FFC107',
    transition: 0.3
  }
})

const CustomButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    textAlign: 'center',
    color: '#000'
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  justifyContent: 'right',
  textAlign: 'right',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    textAlign: 'center'
  },
}));

const Empresa = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { empresa } = props.empresa
  const { isLoading } = props.empresa

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('razaosocial');
  const [direction] = useState('asc');

  const [status, setStatus] = useState('');

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const { totalRegistros } = useSelector(
    (state) => state.empresa
  )

  const buttons = [
    <ButtonFilter size="small" variant="outlined" onClick={() => setStatus('P')}>Pendentes</ButtonFilter>,
    <ButtonFilter size="small" variant="outlined" onClick={() => setStatus('A')}>Aprovadas</ButtonFilter>,
    <ButtonFilter size="small" variant="outlined" onClick={() => setStatus('R')}>Recusadas</ButtonFilter>,
    <ButtonFilter size="small" variant="outlined" onClick={() => setStatus('')}>Todas</ButtonFilter>
  ];

  useEffect(() => {
    if (status !== '') {
      setStatus('')
    }
  }, [])

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        Status: status,
        IdOrgao: idOrgao
      };

      dispatch(empresaAction.getEmpresa(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, status, idOrgao]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir esta empresa?',
      description: <ConfirmationText />,
      disabled: props.empresa.isLoading,
      confirmationText: props.empresa.isLoading ? 'Excluindo empresa...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(empresaAction.deleteEmpresaById(id))
    })
  }

  function ConfirmationText() {
    return (
      <>
        Essa operação é irreversível. <span style={{ color: '#ff3333' }}>Todos os usuários vinculados a ela serão inativados.</span>
      </>
    );
  }


  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Page className={classes.root} title="Empresas">
      <AccessControl
        rule={'empresas.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {empresa && undefined !== empresa && empresa.length > 0 ? (
              <TitleContent
                rule={'empresas.create'}
                length={empresa.length}
                subTitle={'GERÊNCIA DE EMPRESA'}
                title={'Lista de Empresas'}
                href={'/empresa'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_EMPRESA}
              />
            ) : (
              <TitleContent
                rule={'empresas.create'}
                length={0}
                subTitle={'GERÊNCIA DE EMPRESA'}
                title={'Lista de Empresas'}
                href={'/empresa'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_EMPRESA}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
              {authActions.isGestor() &&
                <Grid item xl={12} lg={12} md={12} xs={12} align="right">
                  <FormControl>
                    <FormLabel>
                      <CustomTypography variant="subtitle1">Filtrar por Status </CustomTypography>
                    </FormLabel>
                    <CustomButtonGroup size="small" variant="outlined">
                      {buttons}
                    </CustomButtonGroup>
                  </FormControl>
                </Grid>
              }
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Razão Social</TableCell>
                    <TableCell>Nome Fantasia</TableCell>
                    <TableCell>Nota Média</TableCell>
                    <TableCell>Status</TableCell>
                    {!authActions.isPrefeitura() ? <TableCell>Termos de Uso</TableCell> : null}
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== empresa && empresa.length
                    ? empresa.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.empresa && n.empresa.razaoSocial ? n.empresa.razaoSocial.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.empresa && n.empresa.nomeFantasia ? n.empresa.nomeFantasia.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.totalAvaliacoes != 0 ? <Stars nota={n.mediaNota} avaliacoes={n.totalAvaliacoes} /> : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.empresa?.cadastroLiberado == true ? 'CADASTRO LIBERADO' :
                              n.empresa?.cadastroLiberado == false ? 'CADASTRO RECUSADO' :
                                n.empresa?.cadastroLiberado == null && 'PENDENTE'}
                          </TableCell>
                          {!authActions.isPrefeitura() ?
                            <TableCell component="th" scope="row" className={classes.textField}>
                              {n.empresa?.aceiteTermos == true ? 'ACEITO' : 'PENDENTE'}
                            </TableCell>
                            : null}
                          <TableCell align="right">
                            {authActions.isPrefeitura() ?
                              <AccessControl
                                rule={'empresas.list'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="list"
                                    component="a"
                                    onClick={() =>
                                      history.push(`/empresa/${n.empresa?.id}`)
                                    }
                                    size="small"
                                  >
                                    <Tooltip title={'Visualizar'}>
                                      <Tooltip >
                                        <VisibilityIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              : null}
                            <AccessControl
                              rule={'empresas.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/empresa/${n.empresa?.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Editar'}>
                                    <Tooltip >
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'empresas.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleClick(n.empresa?.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={!authActions.isPrefeitura() ? 6 : 5} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            {!authActions.isPrefeitura() ?
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              : null}
                          </TableRow>
                        ))
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div>
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
Empresa.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedEmpresaPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(Empresa))
)
export { connectedEmpresaPage as Empresa }
